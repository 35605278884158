import React, { useEffect, useState } from "react";
import styles from "../dashboardStyles/dashboardStyle.module.scss";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import usePartner from "../../../../CustomHook/PartnerHook/usePartner";
import { useSelector } from "react-redux";
import axiosClient from "../../../../Utils/Axios/AxiosClient";
import { useNavigate } from "react-router-dom";
import BrokenPage from "../../../../Component/BrokenPage/BrokenPage";
import AuthFailed from "../../../../Component/BrokenPage/AuthFailed";
import Lottie from "lottie-react";
import skillLottie from "../../../../Assets/lottie/LottieSkillPage.json";
import toast from "react-hot-toast";
const Skills = () => {
  const userState = useSelector((state) => state.partner);
  const [selectedSkills, setSelectedSkills] = useState(new Set());
  const [allSkills, setAllSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {

    if (userState && userState.user) {
      setSelectedSkills(new Set(userState.user.skill_ids));


      const fetchSkills = async () => {
        try {
          const result = await axiosClient.get(
            `/${userState.user.user_id}/skills/skilllist`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (result.data) {
            setAllSkills(result.data);
          }
        } catch (error) {
          toast.error("Error Fetching Skills List!")
          setError(error);
        } finally {
          setLoading(false);
        }
      };
      fetchSkills();
    }
  }, []);

  const handleSkillChange = (event, skillId) => {
    const isChecked = event.target.checked;
    console.log(isChecked);

    if (isChecked) {
      // If the skill is checked, add it to the selectedSkills set
      setSelectedSkills(
        (prevSelectedSkills) => new Set([...prevSelectedSkills, skillId])
      );
    } else {
      // If the skill is unchecked, remove it from the selectedSkills set
      const newSelectedSkills = new Set(selectedSkills);
      console.log(newSelectedSkills);
      newSelectedSkills.delete(skillId);
      setSelectedSkills(newSelectedSkills);
    }
  };

  const handleSaveSkills = async () => {
    const skillData = Array.from(selectedSkills);
    try {
      const result = await axiosClient.put(
        `/${userState.user.user_id}/skills`,
        { skills: skillData },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Skills updated successfully!")
      navigate("/dashboard");
    } catch (error) {
      toast.error("Skills updation failed!")
      setError(error);
      console.error("Error updating skills:", error);
    }
  };
  return (
    <div className={styles.skillsContainer}>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        error.response && error.response.status === 401 ? (
          <AuthFailed />
        ) : (
          <BrokenPage />
        )
      ) : (
        <>
          <h2>Unlock Opportunities: Discover, Showcase, and Connect.</h2>
          <p className={styles.skillspara}>
            Seamlessly feature your unique drone skills on our platform and soar
            toward exciting job opportunities and projects. Let your expertise
            shine as we match your skills with the right gigs, amplifying your
            potential for growth and success in the dynamic world of drone
            services.
          </p>
          <div className={styles.skillsPageImg}>
            <Lottie
              animationData={skillLottie}
              autoplay={true}
              className={styles.skillImageContainer}
            />
          </div>

          <div className={styles.dashboardGridContainer}>
            {allSkills.map((skill, index) => (
              <FormControlLabel
                className={styles.skillCheckbox}
                key={index}
                control={
                  <Checkbox
                    onChange={(event) =>
                      handleSkillChange(event, skill.skill_id)
                    }
                    style={{ fontSize: "16px" }}
                    checked={selectedSkills.has(skill.skill_id)}
                  />
                }
                label={
                  <span
                    style={{
                      margin: "0",
                      fontSize: "16px",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {skill.skill_name}
                  </span>
                }
              />
            ))}
          </div>
          <div
            style={{ textAlign: "center" }}
            className={styles.skillBtnContainer}
          >
            <button
              className={styles.centeredButton}
              onClick={handleSaveSkills}
            >
              Save
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Skills;
