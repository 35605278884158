import React, { useEffect, useState } from "react";
import styles from "../dashboardStyles/dashboardStyle.module.scss";
import { Link, Outlet, useLocation } from "react-router-dom";
import useService from "../../../../CustomHook/ServiceHook/useService";
import noImageFound from "../../../../Assets/images/noImgFound.png";
import noDataSvg from "../../../../Assets/svg/noData.svg";
import BrokenPage from "../../../../Component/BrokenPage/BrokenPage";
import AuthFailed from "../../../../Component/BrokenPage/AuthFailed";
import DeleteBox from "../../../../Component/DeleteBox/DeleteBox";
import { useSelector } from "react-redux";

const MyService = () => {
  const location = useLocation();
  const isSubroute = location.pathname.startsWith("/dashboard/service/addservice");
  const { getAllServices, deleteService, services, loading, error } =
    useService();
  const [delConfirmation, setDelConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState("");
  const userState = useSelector((state) => state.partner);

  useEffect(() => {
    if (userState && userState.user) {
      getAllServices();
    }
  }, []);

  return (
    <div className={styles.dashboardContainer}>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        error.response && error.response.status === 401 ? (
          <AuthFailed />
        ) : (
          <BrokenPage />
        )
      ) : services.length > 0 ? (
        <div className={styles.dashboardGridContainer}>
          {services.map((service) => (
            <div
              key={service.service_id}
              className={styles.dashboardCardWrapper}
            >
              <div className={styles.dashboardCard}>
                <div className={styles.dashboardCardImageContainer}>
                  <img
                    src={service.media_url}
                    alt="Service"
                    className={styles["drone-card-img"]}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = noImageFound;
                    }}
                  />
                </div>
                <div className={styles.dashboardCardBody}>
                  <div className={styles.dashboardCardStack}>
                    <h5 className={styles.dashboardCardCategoryButton}>
                      {service.category}
                    </h5>
                    <h5 className={styles.dashboardCardVerifiedButton}>
                      {service.verified ? "Verified" : "Not Verified"}
                    </h5>
                  </div>
                  <div className={styles.dashboardCardBody}>
                    <h5 className={styles.dashboardCardCategoryTitle}>
                      {service.service_name
                        ? service.service_name
                        : "Fetching..."}
                    </h5>
                    <p className={styles.cardPrice}>
                      <strong>₹</strong> {service.service_price}
                    </p>
                    <button
                      className={styles.dashboardCardDeleteButton}
                      onClick={() => {
                        setDeleteId(service.service_id);
                        setDeleteName(service.service_name);
                        setDelConfirmation(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {delConfirmation && (
            <DeleteBox
              deleteFunc={deleteService}
              id={deleteId}
              deleteName={deleteName}
              setDelConfirmation={setDelConfirmation}
            />
          )}
        </div>
      ) : (
        <div className={styles.noDataFound}>
          <img src={noDataSvg} alt="No Data" />
        </div>
      )}
    </div>
  );
};

export default MyService;
