import React, { useEffect, useState } from "react";
// MUI
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Avatar from "../Avatar/Avatar";

import "./toolbar.css";
import image from "../../Assets/logo/abdark2.png";
// font awesome Icons

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimesCircle, faXmark } from "@fortawesome/free-solid-svg-icons";

import RangeSlider from "./RangeSlider";
import { useDispatch } from "react-redux";
import { logOutPartner } from "../../Redux/Partner/PartnerAuthSlice";
import { useNavigate } from "react-router-dom";
import usePartner from "../../CustomHook/PartnerHook/usePartner.js";
import toast from "react-hot-toast";

const Toolbar = ({ setopensidebar, sidebaropen, userData }) => {
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [sliderValue, setSliderValue] = useState(0);
  const [showProfileDetails, setShowProfileDetails] = useState(false);
  const [openLocSelector, setOpenLocSelector] = useState(false);
  const [openDistSelector, setOpenDistSelector] = useState(false);
  // const [error,setError] = useState(null);
  const [showCity, setShowCity] = useState("");

  const {
    getAllLocation,
    setUserLocation,
    setUserWorkingDistance,
    cities,
    states,
  } = usePartner();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      setShowCity(userData.city_name);
    }
    const fetchData = async () => {
      if ((!states.length || !cities.length) && userData) {
        getAllLocation();
      }
    };
    fetchData();
  }, [states.length, cities.length, userData]);

  useEffect(() => {
    if (userData) {
      setSliderValue(userData.reach_in_kms);
      setSelectedState(userData.state_id);
      setSelectedCity(userData.city_id);
      setSliderValue(userData.reach_in_kms);
    }
  }, [userData]);

  const handleCountryChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedCity(""); // Reset city when the country changes
  };

  const handleCityChange = async (event) => {
    setSelectedCity(event.target.value);
    let city = cities.find((city) => city.city_id === event.target.value);
    setShowCity(city.city_name);

    setUserLocation({
      city_id: event.target.value,
      state_id: city.state_id,
    });
  };
  const handleRangeSelect = () => {
    let distance = 0;
    if (sliderValue) {
      distance = sliderValue;
    }
    setUserWorkingDistance({ workingDistance: distance });
    setOpenDistSelector(false);
  };

  const handleLogout = () => {
    dispatch(logOutPartner({}));
    toast.success("User logged out successfully!");
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="toolbar">
      <div className="logo">
        <div className="imageContainer1">
          <img src={image} alt="" onClick={()=>{
            navigate('/dashboard')
          }} />
        </div>
      </div>
      <div className="mobilelogo">
          <FontAwesomeIcon onClick={() => setopensidebar(!sidebaropen)} className="hamburgerIcon" icon={faBars} />
      </div>
      <div className="menu">
        <div
          className="sm-button"
          onClick={() => {
            setOpenLocSelector(true);
            setOpenDistSelector(false);
          }}
        >
          <span>{showCity ? showCity : "Select Location"}</span>
        </div>
        {openLocSelector && (
          <div className="location-options">
            <FontAwesomeIcon
              className="close-selector-box"
              icon={faXmark}
              onClick={() => {
                setOpenLocSelector(false);
              }}
            />
            <h3>Select Location</h3>
            <FormControl className="location-select" fullWidth>
              <InputLabel id="country-label">States</InputLabel>
              <Select
                labelId="country-label"
                id="country-select"
                value={selectedState}
                label="Country"
                onChange={handleCountryChange}
              >
                {states.map((state) => (
                  <MenuItem key={state.state_id} value={state.state_id}>
                    {state.state_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="location-select" fullWidth>
              <InputLabel id="city-label">City</InputLabel>
              <Select
                labelId="city-label"
                id="city-select"
                value={selectedCity}
                label="City"
                onChange={handleCityChange}
                disabled={!selectedState}
              >
                {cities
                  .filter((city) => city.state_id === selectedState)
                  .map((city) => (
                    <MenuItem key={city.city_id} value={city.city_id}>
                      {city.city_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        )}
        <div
          className="sm-button"
          onClick={() => {
            setOpenDistSelector(true);
            setOpenLocSelector(false);
          }}
        >
          <span>{sliderValue ? `${sliderValue}KM` : "Select Radius"}</span>
          {/* <span>
            <b>{sliderValue ? `${sliderValue}KM` : "Select"}</b>
          </span> */}
        </div>
        {openDistSelector && (
          <div className="range-selector">
            <FontAwesomeIcon
              className="close-selector-box"
              icon={faXmark}
              onClick={() => {
                setOpenDistSelector(false);
              }}
            />
            <h3>Distance</h3>
            <RangeSlider
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
            />
            <p>
              We'll match you under 15-km range apart from some special
              missions.
            </p>
            <button
              className="saveBtn"
              onClick={() => {
                handleRangeSelect();
              }}
            >
              Save
            </button>
          </div>
        )}
        <div
          className="profileImg"
          onClick={() => {
            setShowProfileDetails(true);
          }}
        >
          {userData ? (
            <img src={userData.profile_image_url} alt="" />
          ) : (
            <Avatar
              name={userData ? userData.username : "Fetching..."}
              width="40px"
              height="40px"
              setShowProfileDetails={setShowProfileDetails}
              hover={"true"}
            />
          )}
        </div>
        {showProfileDetails && (
          <div className="show-more-profile-data">
            <FontAwesomeIcon
              className="close-popup"
              icon={faTimesCircle}
              onClick={() => {
                setShowProfileDetails(false);
              }}
            />
            <Avatar
              name={userData ? userData.username : "Fetching..."}
              width="80px"
              height="80px"
              fontSize="16px"
              hover={"false"}
            />
            <h3>{userData ? userData.username : "Fetching..."}</h3>
           
            <div
              className="profile-options"
              onClick={() => {
                navigate("/dashboard/partner/editprofile");
                setShowProfileDetails(false)
              }}
            >
              Edit Profile
            </div>
            <button className="logout-user-btn" onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Toolbar;
