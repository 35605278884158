import React, { useEffect, useState } from "react";
import styles from "../dashboardStyles/dashboardStyle.module.scss";
import { useLocation } from "react-router-dom";
import useDrone from "../../../../CustomHook/DroneHook/useDrone";
import noImageFound from "../../../../Assets/images/noImgFound.png";
import noDataSvg from "../../../../Assets/svg/noData.svg";
import BrokenPage from "../../../../Component/BrokenPage/BrokenPage";
import AuthFailed from "../../../../Component/BrokenPage/AuthFailed";
import DeleteBox from "../../../../Component/DeleteBox/DeleteBox";
import { useSelector } from "react-redux";

const MyDrone = () => {
  const location = useLocation();
  const isSubroute = location.pathname.startsWith(
    "/dash/equipment/addequipment"
  );
  const [delConfirmation, setDelConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState("");
  const { getAllDrones, deleteDrone, drones, loading, error } = useDrone();
  const userState = useSelector((state) => state.partner);

  useEffect(() => {
    if (userState && userState.user) {
      getAllDrones();
    }
  }, []);

  return (
    <div className={styles.dashboardContainer}>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        error.response && error.response.status === 401 ? (
          <AuthFailed />
        ) : (
          <BrokenPage />
        )
      ) : drones.length > 0 ? (
        <div className={styles.dashboardGridContainer}>
          {drones.length > 0 &&
            drones.map((drone, index) => (
              <div key={index} className={styles.dashboardCardWrapper}>
                <div className={styles.dashboardCard}>
                  <div className={styles.dashboardCardImageContainer}>
                    <img
                      src={drone.media_url}
                      alt={drone}
                      className={styles["drone-card-img"]}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = noImageFound;
                      }}
                    />
                  </div>
                  <div className={styles.dashboardCardBody}>
                    <div className={styles.dashboardCardStack}>
                      <h5 className={styles.dashboardCardCategoryButton}>
                        {drone.category ? drone.category : "Fetching.."}
                      </h5>
                      <h5 className={styles.dashboardCardVerifiedButton}>
                        {drone.verified ? "Verified" : "Not Verified"}
                      </h5>
                    </div>
                    <div className={styles.dashboardCardBody}>
                      <h5 className={styles.dashboardCardCategoryTitle}>
                        {drone.drone_name}
                      </h5>
                      <button
                        className={styles.dashboardCardDeleteButton}
                        onClick={() => {
                          setDeleteId(drone.drone_id);
                          setDeleteName(drone.drone_name);
                          setDelConfirmation(true);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {delConfirmation && (
            <DeleteBox
              deleteFunc={deleteDrone}
              id={deleteId}
              deleteName={deleteName}
              setDelConfirmation={setDelConfirmation}
            />
          )}
        </div>
      ) : (
        <div className={styles.noDataFound}>
          <img src={noDataSvg} alt="No Data" />
        </div>
      )}
    </div>
  );
};

export default MyDrone;
