import React from 'react'
import style from "./PartnerSocialPost.module.css"
import img1 from "../../../Assets/images/post.jpg"
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ForumIcon from '@mui/icons-material/Forum';
import ShareIcon from '@mui/icons-material/Share';

const PartnerSocialPost = () => {
  return (
    <>
    <div className={style.box}> 

<div className={style.postCard}>
    <header>
    <div className={style.avatar}>

    <img src='https://cdn-icons-png.flaticon.com/512/9131/9131529.png'/>

    </div> 

    <div className={style.userheading}> 
   
    <h1> Vaishali <span>is in Location</span> </h1>
    
    <div className={style.datebox}>

       <p> Dec 20, 2024 </p> 
       <span>.</span>
       <div className='icon'></div>



</div>
     
    </div>
    </header>
   
    <div className={style.caption}> "Lorem Ipsum is simply dummy text of the printing and typesetting" <span> #hashteg1 #hashteg2 </span> </div>


    <div className={style.imageContainer}>
         
         <img src={img1} alt='Banner'/>
      
         </div>
      
         <div className={style.socialIcon}>
        <div className={style.left}>
           <div className={style.likeicon}>
              <ThumbUpIcon color='white'/>
              <p>1</p>
           </div>
           <div className={style.commentIcon}>
            <ForumIcon color='white'/>
           <p>2</p>
           </div>
        </div>
      <div className={style.right}>
        <div className={style.shareIcon}>
          <ShareIcon color='white'/>
        <p>3</p>
        </div>
        </div>      
         </div>
      

    

    </div>
    </div> 
    </>
  )
}

export default PartnerSocialPost