import React, { useEffect, useState } from "react";
import styles from "../dashboardStyles/dashboardStyle.module.scss";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import useCertificate from "../../../../CustomHook/CertificateHook/useCertificate";
import noImageFound from "../../../../Assets/images/noImgFound.png";
import noDataSvg from "../../../../Assets/svg/noData.svg";
import BrokenPage from "../../../../Component/BrokenPage/BrokenPage";
import AuthFailed from "../../../../Component/BrokenPage/AuthFailed";
import DeleteBox from "../../../../Component/DeleteBox/DeleteBox";
import { useSelector } from 'react-redux';

const MyCertification = () => {
  const navigate = useNavigate();

  const redirect = () => {
    navigate("/dashboard/certificate/editcertificate", {
      state: { exampleKey: "exampleValue" },
    });
  };
  const userState = useSelector((state) => state.partner);
  const [delConfirmation, setDelConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState("");

  const {
    getAllCertificates,
    deleteCertificate,
    certificates,
    loading,
    error,
  } = useCertificate();

  useEffect(() => {
    if(useState && userState.user){
      getAllCertificates();
    }
  }, []);

  return (
    <>
      <div className={styles.dashboardContainer}>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          error.response && error.response.status === 401 ? (
            <AuthFailed />
          ) : (
            <BrokenPage />
          )
        ) : certificates.length > 0 ? (
          <div className={styles.dashboardGridContainer}>
            {certificates.map((certificate) => (
              <div
                key={certificate.certificate_id}
                className={styles.dashboardCardWrapper}
              >
                <div className={styles.dashboardCard}>
                  <div className={styles.dashboardCardImageContainer}>
                    <img
                      src={certificate.media_url}
                      alt={certificate}
                      className={styles["drone-card-img"]}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = noImageFound;
                      }}
                    />
                  </div>
                  <div className={styles.dashboardCardBody}>
                    <div className={styles.dashboardCardStack}>
                      <h5 className={styles.dashboardCardCategoryButton}>
                        {certificate.certificate_issued_by}
                      </h5>
                      <h5 className={styles.dashboardCardVerifiedButton}>
                        {certificate.verified ? "Verified" : "Not Verified"}
                      </h5>
                    </div>
                    <div className={styles.dashboardCardBody}>
                      <h5 className={styles.dashboardCardCategoryTitle}>
                        {certificate.certificate_name}
                      </h5>
                      <button
                        className={styles.dashboardCardDeleteButton}
                        onClick={() => {
                          setDeleteId(certificate.certificate_id);
                          setDeleteName(certificate.certificate_id);
                          setDelConfirmation(true);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {delConfirmation && (
              <DeleteBox
                deleteFunc={deleteCertificate}
                id={deleteId}
                deleteName={deleteName}
                setDelConfirmation={setDelConfirmation}
              />
            )}
          </div>
        ) : (
          <div className={styles.noDataFound}>
            <img src={noDataSvg} alt="No Data" />
          </div>
        )}
      </div>
    </>
  );
};

export default MyCertification;
