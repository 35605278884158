import React from 'react'
import style from "./AddFlyingSpotLocation.module.css"
import location from "../../../Assets/images/location.png"

const AddFlyingSpotLocation = () => {
  return (
    <div className={style.Box} >
      <header> <h1> Add New Flying Spot</h1></header>
   
    <div className={style.container}>
     <h3>Choose Spot Location</h3>
    <div className={style.spotlocationMapContainer}>
   
 <p>Locations</p>
 <div className={style.imageContainer}><img src={location}></img></div>
 <div className={style.stack}>
    <h1>BITS PILANI</h1>
    <button >change</button>
 </div>
 <div className={style.stack}>
  <p>Add more locations</p>
 </div>

    </div>

<div className={style.continueBtn}> Continue</div>
<div className={style.stack}>
    <div className={style.cancelBtn}>cancel</div>
    <div className={style.nextBtn}>Next</div>
</div>
  </div>
  </div>
  )
}

export default AddFlyingSpotLocation