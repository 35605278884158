import React from 'react';
import style from './PartnerSocialProfile.module.css';

const PartnerSocialProfile = () => {
  return (
    <div className={style.box}>
      <div className={style.profileContainer}>
        <header>
          <h1>Social Profile</h1>
        </header>
        <div className={style.profileInfoBox}>
          <div className={style.profileCard}>
            <header>Total Post</header>
            <footer>29</footer>
          </div>
          <div className={style.profileCard}>
            <div>Total Group Joined</div>
            <div>5</div>
          </div>
          <div className={style.profileCard}>
            <div>Your Groups</div>
            <div>6</div>
          </div>
          <div className={style.profileCard}>
            <div>Your Followers</div>
            <div>1,125</div>
          </div>
        </div>
        <footer>
          <div>My Activity</div>
          <div>My Post</div>
          <div>Saved</div>
        </footer>
      </div>
    </div>
  );
};

export default PartnerSocialProfile;
