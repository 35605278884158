import styles from "./Profile.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import usePartner from "../../../CustomHook/PartnerHook/usePartner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faSquareInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import AuthFailed from "../../../Component/BrokenPage/AuthFailed";
import BrokenPage from "../../../Component/BrokenPage/BrokenPage";
import { useNavigate, useParams } from "react-router-dom";
import logoLight from '../../../Assets/logo/abLight2.png'

const Profile = () => {
  const userState = useSelector((state) => state.partner);
  const navigate = useNavigate()
  
  
  const { getFullProfile, profilePgData, error } = usePartner();

  const { userIdParam } = useParams();

  useEffect(() => {
    getFullProfile(userIdParam);
  }, []);

  const [slides, setSlides] = useState(1); // Default to 1 slide

  useEffect(() => {
    const updateSlides = () => {
      const width = window.innerWidth;
      if (width > 1500) {
        setSlides(4);
      } else if (width > 850) {
        setSlides(3);
      } else if (width > 500) {
        setSlides(2);
      } else {
        setSlides(1);
      }
    };
    updateSlides();
    window.addEventListener("resize", updateSlides);
    return () => {
      window.removeEventListener("resize", updateSlides);
    };
  }, []);

  // Function to extract YouTube video ID from URL
  function getYouTubeVideoId(url) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : null;
  }

  return error ? (
    error.response && error.response.status === 401 ? (
      <AuthFailed />
    ) : (
      <BrokenPage />
    )
  ) : !(profilePgData && profilePgData.user) ? 
    <div className={styles.loadingScreen}>
      <img src="https://aerialborne.com/assets/logo/logo-icon.webp"/>
    </div>
  : (
    <div className={styles.profileContainer}>
      <div className={styles.about}>
        <div className={styles.aboutText}>
          <h1>
            I'm <span>{profilePgData && profilePgData.user && profilePgData.user.username}</span>
          </h1>
          <h3>
            {profilePgData && profilePgData.user && profilePgData.user.partner_role && (
              <span>
                {profilePgData.user.partner_role.charAt(0).toUpperCase() +
                  profilePgData.user.partner_role.slice(1)}
              </span>
            )}{" "}
            | AerialBorne
          </h3>

          <div className={styles.social}>
            <h3>Follow me On </h3>
            {profilePgData && profilePgData.user && profilePgData.user.facebook_link && 
              <a href={profilePgData && profilePgData.user && profilePgData.user.facebook_link}>
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            }

            {profilePgData && profilePgData.user && profilePgData.user.linkedin_link && 
              <a href={profilePgData && profilePgData.user && profilePgData.user.linkedin_link}>
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            }

            {profilePgData && profilePgData.user && profilePgData.user.instagram_link && 
              <a href={profilePgData && profilePgData.user && profilePgData.user.instagram_link}>
                <FontAwesomeIcon icon={faSquareInstagram} />
              </a>
            }

            {profilePgData && profilePgData.user && profilePgData.user.youtube_link && 
              <a href={profilePgData && profilePgData.user && profilePgData.user.youtube_link}>
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            }
          </div>
        </div>
        <div className={styles.imgContainer}>
          <img
            src={profilePgData && profilePgData.user && profilePgData.user.profile_image_url}
            alt=""
            className="partnerImg"
          />
        </div>
      </div>
      {profilePgData && profilePgData.services.length > 0 && (
        <section className={styles.services}>
          <h1 className={styles.sectionHeading}>
            <span>{/* Icon */}</span>
            <span>My Services</span>
          </h1>

          <div className={styles.sectionContent}>
            <Swiper
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              modules={[Navigation]}
              slidesPerView={
                profilePgData.services.length < slides
                  ? profilePgData.services.length
                  : slides
              }
              spaceBetween={30}
              className={styles.mySwiper}
            >
              {profilePgData.services.map((service, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className={styles.sectionCard}>
                      <div className={styles.sectionCardImg}>
                        <img src={service.media_url} alt="" />
                      </div>
                      <div className={styles.sectionCardBody}>
                        <div className={styles.sectionCardData}>
                          <h3>{service.service_name}</h3>
                          <p>{service.category}</p>
                        </div>
                        <p className={styles.servicePrice}>Starts From ₹{service.service_price} </p>
                        <p className={styles.serviceDescription}>{service.service_description} </p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </section>
      )}
      {profilePgData && profilePgData.equipments.length > 0 && (
        <section className={styles.services}>
          <h1 className={styles.sectionHeading}>
            <span>{/* Icon */}</span>
            <span>My Equipments</span>
          </h1>

          <div className={styles.sectionContent}>
            <Swiper
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              modules={[Navigation]}
              slidesPerView={
                profilePgData.equipments.length < slides
                  ? profilePgData.equipments.length
                  : slides
              }
              spaceBetween={30}
              className={styles.mySwiper}
            >
              {profilePgData.equipments.map((equipment, index) => {
                return (
                  <SwiperSlide className={styles.swiperSlide} key={index}>
                    <div className={styles.sectionCard}>
                      <div className={styles.sectionCardImg}>
                        <img src={equipment.media_url} alt="" />
                      </div>

                      <div className={styles.sectionCardBody}>
                        <div className={styles.sectionCardData}>
                          <h3>{equipment.equipment_name}</h3>
                          <p>{equipment.category}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </section>
      )}

      {profilePgData && profilePgData.showreels.length > 0 && (
        <section className={styles.services}>
          <h1 className={styles.sectionHeading}>
            <span>{/* Icon */}</span>
            <span>My Showreels</span>
          </h1>

          <div className={styles.sectionContent}>
            <Swiper
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              modules={[Navigation]}
              slidesPerView={
                profilePgData.showreels.length < slides
                  ? profilePgData.showreels.length
                  : slides
              }
              spaceBetween={30}
              className={styles.mySwiper}
            >
              {profilePgData.showreels.map((showreel, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className={styles.sectionCard}>
                      <div className={styles.sectionCardImg}>
                        <iframe
                          width="560"
                          height="222"
                          src={`https://www.youtube.com/embed/${getYouTubeVideoId(showreel.media_url)}`}
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>

                      <div className={styles.sectionCardBody}>
                        <div className={styles.sectionCardData}>
                          <h3>{showreel.showreel_title}</h3>
                          <p>Media Clip</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </section>
      )}

      <div className={styles.skills}>
        <div className={styles.skillsData}>
          <h1 className={styles.skillsHeading}>
            <span>{/* Icon */}</span>
            <span>Skills</span>
          </h1>
          <div className={styles.skillsContent}>
            {profilePgData && profilePgData.user && profilePgData.user.skills && profilePgData.user.skills.split(",").map((skill, index) => {
              return <p key={index}>{skill}</p>;
            })}
          </div>
        </div>
      </div>

      <footer className={styles.profileFooter}>
        <div className={styles.footerContacts}>
          <div className={styles.footerLogo}  onClick={()=>{
            navigate('/')
          }}>
          <img src={logoLight} className="logo-img" alt="" />
          <p>Get your professional website for free!</p>
          </div>
          <div className={styles.connectionDetails}>
            <h1 className={styles.letsConnect}>Let's Connect</h1>
            <div className="contactNumber">
              <h4>Contact Number</h4>
              <a>{profilePgData && profilePgData.user && profilePgData.user.contact_number}</a>
            </div>
            <div className="email">
              <h4>Email</h4>
              <a >{profilePgData && profilePgData.user && profilePgData.user.email}</a>
            </div>
            <div className="Address">
              <h4>Address</h4>
              <span>{profilePgData && profilePgData.user && profilePgData.user.address}</span>
            </div>
          </div>
        </div>

      </footer>
    </div>
  );
};

export default Profile;
