import { Suspense, lazy, useEffect } from "react";
import {
  Navigate,
  useNavigate,
  useRoutes,
  useLocation,
} from "react-router-dom";
import MyDashboard from "../Pages/Partner/Dashboard/MyDashboard/MyDashboard";
import MyEquipments from "../Pages/Partner/Dashboard/MyEqipments/MyEquipments";
import MyService from "../Pages/Partner/Dashboard/MyService/MyService";
import MyShowreel from "../Pages/Partner/Dashboard/MyShowreel/MyShowreel";
import MyExperience from "../Pages/Partner/Dashboard/MyExperience/MyExperience";
import DroneForm from "../Pages/Partner/DashboardForm/DroneForm/DroneForm";
import CerificationForm from "../Pages/Partner/DashboardForm/CertificationForm/CerificationForm";
import EquipmentsForm from "../Pages/Partner/DashboardForm/EquipmentsForm/EquipmentsForm";
import ServicesForm from "../Pages/Partner/DashboardForm/ServicesForm/ServicesForm";
import ShowreelForm from "../Pages/Partner/DashboardForm/ShowreelForm/ShowreelForm";
import ExperiencesForm from "../Pages/Partner/DashboardForm/ExperiencesForm/ExperiencesForm";
import AuthLayout from "../Layouts/Partner/AuthLayout";
import DashboardLayout from "../Layouts/Partner/DashboardLayout";
import SectionLayout from "../Layouts/Partner/DashboardSectionLayout/SectionLayout";
import Skills from "../Pages/Partner/Dashboard/Skills/Skills";
import MyCertification from "../Pages/Partner/Dashboard/MyCertification/MyCertification";
import LoadingScreen from "../Component/LoadingScreen/LoadingScreen";
import MyDrone from "../Pages/Partner/Dashboard/MyDrone/MyDrone";
import FlyingSpotLayout from "../Layouts/FlyingSpot/FlyingSpotLayout";
import AddFlyingSpot from "../Pages/FlyingSpot/AddFlyingSpot/AddFlyingSpot";

import AddFlyingSpotLocationDetail from "../Pages/FlyingSpot/AddFlyingSpotLocationDetail/AddFlyingSpotLocationDetail";
import AddFlyingSpotLocationImage from "../Pages/FlyingSpot/AddFlyingSpotLocationImage/AddFlyingSpotLocationImage";
import SingleFlyingSpotDetail from "../Pages/FlyingSpot/SingleFlyingSpotDetail/SingleFlyingSpotDetail";
import AddFlyingSpotLocation from "../Pages/FlyingSpot/AddFlyingSpotLocationn/AddFlyingSpotLocation";
import PartnerSocialLayout from "../Layouts/Social/PartnerSocialLayout";
import PartnerSocialPost from "../Pages/Social/PartnerSocialPost/PartnerSocialPost";
import PartnerSocialGroup from "../Pages/Social/PartnerSocialGroup/PartnerSocialGroup";
import PartnerSocialCreatepost from "../Pages/Social/PartnerSocialCreatepost/PartnerSocialCreatepost";
import PartnerSocialProfile from "../Pages/Social/PartnerSocialProfile/PartnerSocialProfile";
import SignIn from "../Pages/Partner/Auth/signin/SignIn";
import Signup from "../Pages/Partner/Auth/signup/Signup";
import MobileNumberPage from "../Pages/Partner/Auth/signin/signInSteps/MobilePage/MobileNumberPage";
import LoginMethod from "../Pages/Partner/Auth/loginMethod/LoginMethod";
import CoverPage from "../Pages/Partner/CoverPage/CoverPage";

import PartnerEditProfile from "../Pages/Partner/DashboardForm/PartnerForm/PartnerEditProfile";
import PartnerEditSocialIcon from "../Pages/Partner/DashboardForm/PartnerForm/PartnerEditSocialIcon";
import { useSelector } from "react-redux";
import Profile from "../Pages/Partner/Profile/Profile";
import PostRequirement from "../Pages/Partner/DashboardForm/PostRequirement/PostRequirement";
import CompleteProfile from "../Pages/Partner/CompleteProfile/CompleteProfile";

// const PrivateRoute = ({ children }) => {
//   // You can implement your authentication logic here
//   const isAuthenticated = true; // Replace with your actual authentication check

//   // Redirect to the login page if the user is not authenticated
//   if (!isAuthenticated) {
//     return <Navigate to="/login" replace />;
//   }

//   // Render the protected content if the user is authenticated
//   return children;
// };

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const userState = useSelector((state) => state.partner);
  const navigate = useNavigate();
  const location = useLocation();

  // console.log(location);

  useEffect(() => {
    if (!userState.isLoggedIn && !/^\/profile\/.*/.test(location.pathname)) {
      navigate("/");
    }
  }, [userState]);

  return useRoutes([
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        {
          index: true,
          element: <LoginMethod />,
        },
      ],
    },
    {
      path: "/role",
      element: <CoverPage />,
    },
    {
      path: "/complete-profile",
      element: <CompleteProfile/>,
    },
    {
      path: "/profile/:userIdParam",
      element: <Profile />,
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { index: true, element: <MyDashboard /> },
        {
          path: "skills",
          element: <SectionLayout />,

          children: [{ index: true, element: <Skills /> }],
        },

        {
          path: "certificate",
          element: <SectionLayout />,

          children: [
            { index: true, element: <MyCertification /> },
            { path: "addcertificate", element: <CerificationForm /> },
            { path: "editcertificate", element: <CerificationForm /> },
          ],
        },
        {
          path: "drone",
          element: <SectionLayout />,
          children: [
            { index: true, element: <MyDrone /> },
            { path: "adddrone", element: <DroneForm /> },
            { path: "editdrone", element: <DroneForm /> },
          ],
        },
        {
          path: "equipment",
          element: <SectionLayout />,
          children: [
            { index: true, element: <MyEquipments /> },
            { path: "addequipment", element: <EquipmentsForm /> },
            { path: "editequipment", element: <EquipmentsForm /> },
          ],
        },
        {
          path: "service",
          element: <SectionLayout />,
          children: [
            { index: true, element: <MyService /> },
            { path: "addservice", element: <ServicesForm /> },
            { path: "editservice", element: <ServicesForm /> },
          ],
        },
        {
          path: "showreel",
          element: <SectionLayout />,
          children: [
            { index: true, element: <MyShowreel /> },
            { path: "addshowreel", element: <ShowreelForm /> },
            { path: "editshowreel", element: <ShowreelForm /> },
          ],
        },
        {
          path: "myexperience",
          element: <SectionLayout />,
          children: [
            { index: true, element: <MyExperience /> },
            { path: "addexperience", element: <ExperiencesForm /> },
            { path: "editexperience", element: <ExperiencesForm /> },
          ],
        },
        {
          path: "post-requirement",
          element: <SectionLayout />,
          children: [{ index: true, element: <PostRequirement /> }],
        },
        {
          path: "partner",
          element: <SectionLayout />,
          children: [
            { path: "editprofile", element: <PartnerEditProfile /> },
            { path: "editsociallink", element: <PartnerEditSocialIcon /> },
          ],
        },
      ],
    },
    {
      path: "/flyingspot",
      element: <FlyingSpotLayout />,
      children: [
        {
          path: "v1",
          element: <SectionLayout />,
          children: [
            { index: true, element: <AddFlyingSpot /> },
            {
              path: "addlocation",
              element: <AddFlyingSpotLocation />,
            },
            {
              path: "adddetail",
              element: <AddFlyingSpotLocationDetail />,
            },

            {
              path: "addimage",
              element: <AddFlyingSpotLocationImage />,
            },
            {
              path: ":id",
              element: <SingleFlyingSpotDetail />,
            },
          ],
        },
      ],
    },
    {
      path: "/social",
      element: <PartnerSocialLayout />,
      children: [
        {
          path: "v1",
          element: <SectionLayout />,
          children: [
            { index: true, element: <PartnerSocialPost /> },
            {
              path: "groups",
              element: <PartnerSocialGroup />,
            },
            {
              path: "create",
              element: <PartnerSocialCreatepost />,
            },
            {
              path: "profile",
              element: <PartnerSocialProfile />,
            },
          ],
        },
      ],
    },
    // { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// const LoginMethod = Loadable(
//   lazy(() => import("../pages/auth/loginMethod/LoginMethod"))
// );
// const LoginPage = Loadable(lazy(() => import("../pages/auth/signin/SignIn")));
// const SignUpPage = Loadable(lazy(() => import("../pages/auth/signup/Signup")));
// const MyDrone = Loadable(
//   lazy(() => import("../pages/dashboard/MyDrone/MyDrone"))
// );
// const MyPofile = Loadable(
//   lazy(() => import("../pages/dashboard/MyProfile/MyPofile"))
// );
