import React, { useState } from "react";
import style from "../dashboardFormStyle/dashboardFormStyle.module.scss";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useExperience from "../../../../CustomHook/ExperienceHook/useExperience";
import { useLocation, useNavigate } from "react-router-dom";

const ExperiencesForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let prevData = null;
  let experience = null;
  if (location.state) {
    prevData = location.state.experience;
    experience = {
      id: prevData.exp_id,
      title: prevData.exp_title,
      employmentType: prevData.employment_type,
      company: prevData.company_name,
      location: prevData.location,
      locationType: "",
      description: prevData.exp_desc,
      currentlyWorking: false,
      startDate: "",
      endDate: "",
      expMediaUrl: "jpg",
    };
  }
  const { postExperience, updateExperience } = useExperience();
  const [formData, setFormData] = useState(
    experience || {
      title: "",
      employmentType: "",
      company: "",
      location: "",
      locationType: "",
      description: "",
      currentlyWorking: false,
      startDate: null,
      endDate: null,
      expMediaUrl: "jpg",
    }
  );

  const handleDateChange = (date, field) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: date,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const isoStartDate = formData.startDate
      ? formData.startDate.toISOString().split("T")[0]
      : null;
    const isoEndDate = formData.endDate
      ? formData.endDate.toISOString().split("T")[0]
      : null;

    // Create the data object to send to the backend
    const experienceData = {
      ...formData,
      startDate: isoStartDate,
      endDate: isoEndDate,
    };

    if (experience) {
      // Editing existing data
      updateExperience(experience.id, experienceData);
    } else {
      // Adding new data
      postExperience(experienceData);
    }

    navigate("/dashboard/myexperience");
  };

  return (
    <div className={style.dashboardFormContainerWrapper}>
      <div className={style.dashboardFormContainer}>
        <form action="" className={style.FormContainer} onSubmit={handleSubmit}>
          <h2>{experience ? "Edit Experience" : "Add Experience"}</h2>
          <div className={style.inputContainer}>
            <label htmlFor="expTitle">
              <h3>Title*</h3>
            </label>
            <input
              name="title"
              id="expTitle"
              type="text"
              placeholder="Ex. I'm a drone Pilot"
              value={formData.title}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  title: e.target.value,
                }))
              }
              required
            />
          </div>

          {/* Employment Type */}
          <div className={style.inputContainer}>
            <label htmlFor="employmentType">
              <h3>Employment Type*</h3>
            </label>
            <input
              name="employmentType"
              id="employmentType"
              type="text"
              placeholder="Select employment type"
              value={formData.employmentType}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  employmentType: e.target.value,
                }))
              }
              required
            />
          </div>

          {/* Company */}
          <div className={style.inputContainer}>
            <label htmlFor="company">
              <h3>Company Name*</h3>
            </label>
            <input
              name="company"
              id="company"
              type="text"
              placeholder="Enter company name"
              value={formData.company}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  company: e.target.value,
                }))
              }
              required
            />
          </div>

          {/* Location */}
          <div className={style.inputContainer}>
            <label htmlFor="location">
              <h3>Location*</h3>
            </label>
            <input
              name="location"
              id="location"
              type="text"
              placeholder="Enter your location"
              value={formData.location}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  location: e.target.value,
                }))
              }
              required
            />
          </div>

          {/* Location Type */}
          <div className={style.inputContainer}>
            <label htmlFor="locationType">
              <h3>Location Type*</h3>
            </label>
            <input
              name="locationType"
              id="locationType"
              type="text"
              placeholder="Select location type"
              value={formData.locationType}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  locationType: e.target.value,
                }))
              }
              required
            />
          </div>

          {/* Description */}
          <div className={style.inputContainer}>
            <label htmlFor="expdescription">
              <h3>Description*</h3>
            </label>
            <input
              name="description"
              id="expdescription"
              type="text"
              placeholder="Write about what you've done there"
              value={formData.description}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  description: e.target.value,
                }))
              }
              required
            />
          </div>

          {/* Checkbox for Currently Working */}
          <FormControlLabel
            control={<Checkbox />}
            label="I'm currently working in this role"
            checked={formData.currentlyWorking}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                currentlyWorking: e.target.checked,
              }))
            }
          />

          {/* Start Date */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={style.dateField}>
              <div className={style.dateCont}>
                <h3>Start Date*</h3>
                <DatePicker
                  className={style.datePicker}
                  value={formData.startDate}
                  onChange={(date) => handleDateChange(date, "startDate")}
                  required
                />
              </div>

              {/* End Date */}
              <div className={style.dateCont}>
                <h3>End Date*</h3>
                <DatePicker
                  className={style.datePicker}
                  value={formData.endDate}
                  onChange={(date) => handleDateChange(date, "endDate")}
                  required
                />
              </div>
            </div>
          </LocalizationProvider>

          <div className={style.draguploadbutton}>
            <button
              type="button"
              className={style.dashboardFormCancelButton}
              onClick={() => {
                navigate("/dashboard/myexperience");
              }}
            >
              Cancel
            </button>
            <button type="submit" className={style.dashboardFormSaveButton}>
              {experience ? "Save" : "Add"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExperiencesForm;
