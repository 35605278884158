import { createSlice } from '@reduxjs/toolkit';

const SocialSlice = createSlice({
  name: 'social',
  initialState: {
    // Your social state properties go here
  },
  reducers: {
    // Define actions and reducers for the social section
  },
});

export const { /* action creators */ } = SocialSlice.actions;
export default SocialSlice.reducer;