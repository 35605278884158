import React, { useState } from "react";
import style from "../dashboardFormStyle/dashboardFormStyle.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useCustomHookS3 from "../../../../CustomHook/AvatarPreview/useCustomHookS3";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useShowreel from "../../../../CustomHook/ShowreelHook/useShowreel";
import AuthFailed from "../../../../Component/BrokenPage/AuthFailed";
import BrokenPage from "../../../../Component/BrokenPage/BrokenPage";

const ShowreelForm = () => {
  const navigate = useNavigate();
  const { postShowreel } = useShowreel();
  const { uploadFileInS3, imageUrl, error } = useCustomHookS3();
  // const [imageUrl, setImageUrl] = useState(null);

  const [formData, setFormData] = useState({
    showreel_name: "",
    showreel_description: "",
    showreel_category: "",
    showreel_price: "",
    image: null,
    droneMediaUrl: "",
  });
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      setFormData((prevData) => ({
        ...prevData,
        droneMediaUrl: imageUrl,
      }));
      setUploading(false);
      setUploadSuccess(true);
    }
  }, [imageUrl]);

  const handleSubmit = (event) => {
    try {
      event.preventDefault();
      postShowreel(formData);
      navigate("/dashboard/showreel");
    } catch (error) {
      console.log("Error Form Submission!");
    }
  };

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "image") {
      const file = files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFormData((prevData) => ({
            ...prevData,
            image: file,
            droneMediaUrl: reader.result,
          }));
        };
        reader.readAsDataURL(file);
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCancel = () => {
    setFormData((prevData) => ({
      ...prevData,
      image: null,
      droneMediaUrl: "",
    }));
  };

  const handleUpload = () => {
    if (formData.image) {
      setUploading(true);
      const key = `${Date.now()}${formData.image.name}`;
      const contentType = formData.image.type;
      uploadFileInS3(formData.image, key, contentType);
    }
  };

  return (
    <div className={style.dashboardFormContainerWrapper}>
      {error ? (
        error.response && error.response.status === 401 ? (
          <AuthFailed />
        ) : (
          <BrokenPage />
        )
      ) : (
        <div className={style.dashboardFormContainer}>
          <form className={style.FormContainer} onSubmit={handleSubmit}>
            <h2 className={style.dashboardFormContinerHeading}>
              Add New Showreel
            </h2>
            <div className={style.dashboardFormInputContainer}>
              <input
                className={style.dashFormContainerInput}
                name="showreel_name"
                type="text"
                value={formData.service_name}
                placeholder="Enter Showreel Name"
                onChange={handleInputChange}
                required
              />
              <div className={style.formFieldService}>
                <input
                  className={style.dashFormContainerInputService}
                  name="showreel_description"
                  type="text"
                  value={formData.showreel_description}
                  placeholder="Enter Showreel Description"
                  onChange={handleInputChange}
                  required
                />
                <input
                  className={style.dashFormContainerInputService}
                  name="showreel_price"
                  type="number"
                  value={formData.showreel_price}
                  placeholder="Enter Showreel Price"
                  onChange={handleInputChange}
                  min={0}
                  max={1000000}
                />
              </div>
              <div className={style.formField}>
                <div className={style.DragUploader}>
                  <div className={style.DragUploaderHeading}>
                    Enter Youtube URL
                  </div>
                  <div className={style.formFieldService}>
                    <input
                      className={style.dashFormContainerInputService}
                      name="droneMediaUrl"
                      type="text"
                      value={formData.droneMediaUrl}
                      placeholder="Youtube link"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className={style.selectContainer}>
                  <h3>Choose Category</h3>
                  <FormControl
                    sx={{ m: 1, minWidth: 120 }}
                    size="small"
                    className={style.selectCategory}
                  >
                    <InputLabel id="demo-select-small-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={formData.showreel_category}
                      label="Category"
                      onChange={handleInputChange}
                      name="showreel_category"
                      required
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Photography">Photography</MenuItem>
                      <MenuItem value="Videography">Videography</MenuItem>
                      <MenuItem value="Survey Mapping">Survey Mapping</MenuItem>
                      <MenuItem value="Inspection">Inspection</MenuItem>
                      <MenuItem value="Monitoring/Surveillance">
                        Monitoring/Surveillance
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className={style.btnContainer}>
              <button
                className={style.dashboardFormSubmitButton}
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ShowreelForm;
