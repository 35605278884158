import React, { useEffect, useState } from "react";
import styles from "./coverPage.module.scss";
import usePartner from "../../../CustomHook/PartnerHook/usePartner";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import droneOwnerImage from "../../../Assets/images/drone-owner.jpg";
import droneServiceProviderImage from "../../../Assets/images/drone-service-provider.jpg";
import dronePilotImage from "../../../Assets/images/drone-pilot.jpg";
import droneEngineerImage from "../../../Assets/images/drone-engineer.jpg";
import photographerImage from "../../../Assets/images/professional-photographer.jpg";
import BrokenPage from "../../../Component/BrokenPage/BrokenPage";
import AuthFailed from "../../../Component/BrokenPage/AuthFailed";
import toast from "react-hot-toast";
import logoLight from '../../../Assets/logo/abLight2.png'
// import image from '../../../Assets/images/'
const CoverPage = () => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.partner);

  useEffect(() => {
    if (userState.user && userState.isLoggedIn) {
      if (userState.user.partner_role) {
        navigate("/dashboard");
      }
    } else {
      navigate("/");
    }
  }, [userState]);

  const [selectedRole, setSelectedRole] = useState({
    new_role_name: null,
  });
  const { updateUserRole, error } = usePartner();
  const roles = [
    {
      id: 1,
      image: droneOwnerImage,
      role: "Drone Owner",
    },
    {
      id: 2,
      image: droneServiceProviderImage,
      role: "Drone Service Provider",
    },
    {
      id: 3,
      image: dronePilotImage,
      role: "Drone Pilot",
    },
    {
      id: 4,
      image: droneEngineerImage,
      role: "Drone Engineer",
    },
    {
      id: 5,
      image: photographerImage,
      role: "Professional Photographer",
    },
  ];

  const handleRoleClick = (role) => {
    setSelectedRole({
      new_role_name: role,
    });
  };

  const handleUserRole = () => {
    // API Request To Update User Role
      if (selectedRole) {
        updateUserRole(selectedRole);
      }
  };

  return error ? (
    error.response && error.response.status === 401 ? (
      <AuthFailed />
    ) : (
      <BrokenPage />
    )
  ) : (
    <div className={styles.outer}>
      <div className={styles.inner}>
        <div className={styles.container}>
          <div className={styles.heading}>
            <h1>Select Your Role</h1>
            <h2>Get Started with Aerialborne</h2>
          </div>

          <div className={styles.center}>
            <div className={styles.circleButtonsCont}>
              {roles.map((role) => (
                <div
                  key={role.id}
                  className={`${styles.circleButton} ${
                    selectedRole.new_role_name === role.role
                      ? styles.selectedProfile
                      : ""
                  }`}
                  onClick={() => handleRoleClick(role.role)}
                >
                  <div className={styles.circle}>
                    <img src={role.image} alt="" />
                  </div>
                  <p>{role.role}</p>
                </div>
              ))}
            </div>
          </div>

          <button
            disabled={!selectedRole.new_role_name}
            className={styles.nextBtn}
            onClick={handleUserRole}
          >
            Next
          </button>

          <div className={styles.footer}>
            <div className={styles.iconCont}>
              <img src={logoLight} className="logo-img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverPage;
