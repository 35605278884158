import React, { useEffect, useState } from "react";
import styles from "../dashboardStyles/dashboardStyle.module.scss";
import { useLocation } from "react-router-dom";
import useShowreel from "../../../../CustomHook/ShowreelHook/useShowreel"; // Import your custom hook
import noImageFound from "../../../../Assets/images/noImgFound.png";
import noDataSvg from "../../../../Assets/svg/noData.svg";
import BrokenPage from "../../../../Component/BrokenPage/BrokenPage";
import AuthFailed from "../../../../Component/BrokenPage/AuthFailed";
import DeleteBox from "../../../../Component/DeleteBox/DeleteBox";
import { useSelector } from "react-redux";

const MyShowreel = () => {
  const location = useLocation();
  const isSubroute = location.pathname.startsWith("/dash/showreel/addshowreel");
  const { getAllShowreels, deleteShowreel, showreels, loading, error } =
    useShowreel(); // Use the custom hook
  const [delConfirmation, setDelConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState("");
  const userState = useSelector((state) => state.partner);

  useEffect(() => {
    if (userState && userState.user) {
      getAllShowreels();
    }
  }, []);

  // Function to extract YouTube video ID from URL
  function getYouTubeVideoId(url) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : null;
  }

  return (
    <div className={styles.dashboardContainer}>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        error.response && error.response.status === 401 ? (
          <AuthFailed />
        ) : (
          <BrokenPage />
        )
      ) : showreels.length > 0 ? (
        <div className={styles.dashboardGridContainer}>
          {showreels.map((showreel, index) => (
            <div key={index} className={styles.dashboardCardWrapper}>
              <div className={styles.dashboardCard}>
                <div className={styles.dashboardCardImageContainer}>
                  <iframe
                    width="560"
                    height="222"
                    src={`https://www.youtube.com/embed/${getYouTubeVideoId(showreel.media_url)}`}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className={styles.dashboardCardBody}>
                  <div className={styles.dashboardCardStack}>
                    <h5 className={styles.dashboardCardCategoryButton}>
                      {showreel.category ? showreel.category : "Media Clips"}
                    </h5>
                    <h5 className={styles.dashboardCardVerifiedButton}>
                      {showreel.verified ? "Verified" : "Not Verified"}
                    </h5>
                  </div>
                  <div className={styles.dashboardCardBody}>
                    <h5 className={styles.dashboardCardCategoryTitle}>
                      {showreel.showreel_title
                        ? showreel.showreel_title
                        : "Fetching..."}
                    </h5>
                    <p className={styles.cardPrice}>
                      <strong>₹</strong> {showreel.showreel_price}
                    </p>

                    <button
                      className={styles.dashboardCardDeleteButton}
                      onClick={() => {
                        setDeleteId(showreel.showreel_id);
                        setDeleteName(showreel.showreel_title);
                        setDelConfirmation(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {delConfirmation && (
            <DeleteBox
              deleteFunc={deleteShowreel}
              id={deleteId}
              deleteName={deleteName}
              setDelConfirmation={setDelConfirmation}
            />
          )}
        </div>
      ) : (
        <div className={styles.noDataFound}>
          <img src={noDataSvg} alt="No Data" />
        </div>
      )}
    </div>
  );
};

export default MyShowreel;
