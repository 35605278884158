import React, { useEffect, useState } from "react";
import styles from "../dashboardStyles/dashboardStyle.module.scss";
import { useLocation } from "react-router-dom";

import useEquipment from "../../../../CustomHook/EquipmentHook/useEquipment";
import noImageFound from "../../../../Assets/images/noImgFound.png";
import noDataSvg from "../../../../Assets/svg/noData.svg";
import BrokenPage from "../../../../Component/BrokenPage/BrokenPage";
import AuthFailed from "../../../../Component/BrokenPage/AuthFailed";
import DeleteBox from "../../../../Component/DeleteBox/DeleteBox";
import { useSelector } from "react-redux";

const MyEquipments = () => {
  const location = useLocation();
  const userState = useSelector((state) => state.partner);

  const isSubroute = location.pathname.startsWith(
    "/dash/equipment/addequipment"
  );

  const {
    getAllDroneEquipments,
    deleteDroneEquipment,
    droneEquipments,
    loading,
    error,
  } = useEquipment();
  const [delConfirmation, setDelConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState("");

  useEffect(() => {
    if (userState && userState.user) {
      getAllDroneEquipments();
    }
  }, []);

  return (
    <div className={styles.dashboardContainer}>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        error.response && error.response.status === 401 ? (
          <AuthFailed />
        ) : (
          <BrokenPage />
        )
      ) : droneEquipments.length > 0 ? (
        <div className={styles.dashboardGridContainer}>
          {droneEquipments.map((equipment) => (
            <div
              key={equipment.equipment_id}
              className={styles.dashboardCardWrapper}
            >
              <div className={styles.dashboardCard}>
                <div className={styles.dashboardCardImageContainer}>
                  <img
                    src={equipment.media_url || noImageFound}
                    alt={equipment.equipment_name}
                    className={styles["drone-card-img"]}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = noImageFound;
                    }}
                  />
                </div>
                <div className={styles.dashboardCardBody}>
                  <div className={styles.dashboardCardStack}>
                    <h5 className={styles.dashboardCardCategoryButton}>
                      {equipment.category ? equipment.category : "Fetching..."}
                    </h5>
                    <h5 className={styles.dashboardCardVerifiedButton}>
                      {equipment.verified ? "Verified" : "Not Verified"}
                    </h5>
                  </div>
                  <div className={styles.dashboardCardBody}>
                    <h5 className={styles.dashboardCardCategoryTitle}>
                      {equipment.equipment_name
                        ? equipment.equipment_name
                        : "Fetching..."}
                    </h5>
                    <button
                      className={styles.dashboardCardDeleteButton}
                      onClick={() => {
                        setDeleteId(equipment.equipment_id);
                        setDeleteName(equipment.equipment_name);
                        setDelConfirmation(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {delConfirmation && (
            <DeleteBox
              deleteFunc={deleteDroneEquipment}
              id={deleteId}
              deleteName={deleteName}
              setDelConfirmation={setDelConfirmation}
            />
          )}
        </div>
      ) : (
        <div className={styles.noDataFound}>
          <img src={noDataSvg} alt="No Data" />
        </div>
      )}
    </div>
  );
};

export default MyEquipments;
