import React from "react";
import "./BreadCrumb.css"; // Import the stylesheet
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = () => {
  const location = useLocation();

  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb, index, array) => {
      const currentLink = `/${array.slice(0, index + 1).join("/")}`;
      return (
        <div key={index} className="crumb">
          <Link to={currentLink} className="breadcrumb-item">
            {crumb}
          </Link>
        </div>
      );
    });

  return <div className="breadcrumb">{crumbs}</div>;
};

export default Breadcrumb;
