import React, { useState } from "react";
import styles from "./TourCard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faXmark } from "@fortawesome/free-solid-svg-icons";

import leftSwipeImg from "../../Assets/images/TourCardImages/leftSwipe.png";
import rightSwipeImg from "../../Assets/images/TourCardImages/rightSwipe.png";

import droneImg from "../../Assets/images/DashboardImages/droneImg.png";
import showreelImg from "../../Assets/images/TourCardImages/showreelImage.png";
import equipmentImg from "../../Assets/images/DashboardImages/equipmentImage.png";
import certificatImg from "../../Assets/images/DashboardImages/certificatesImg.png";
import experienceImg from "../../Assets/images/DashboardImages/experienceImg.png";
import servicesImg from "../../Assets/images/DashboardImages/servicesImg.png";
import { useNavigate } from "react-router-dom";

export default function TourCard({showTour,setShowTour}) {
  const navigate = useNavigate();
  const data = [
    {
      id: 1,
      title: "Equipments",
      image: equipmentImg,
      path: "equipment",
      btnData: "Equipments",
      description: "Manage your drones & equipment inventory easily."
    },
    {
      id: 2,
      title: "Certificates",
      image: certificatImg,
      path: "certificate",
      btnData: "Certificates",
      description: "Upload your certifications to build trust and credibility."
    },
    {
      id: 3,
      title: "Experience",
      image: experienceImg,
      path: "myexperience",
      btnData: "Experience",
      description: "Demonstrate your professional journey visually"
    },
    {
      id: 4,
      title: "Showreel",
      image: showreelImg,
      path: "showreel",
      btnData: "Showreels",
      description: "Upload your best work, including photos videos."
    },
    {
      id: 5,
      title: "Services",
      image: servicesImg,
      path: "service",
      btnData: "Services",
      description: "Highlight your service offerings."
    },
  ];
  const [counter, setCounter] = useState(0);

  const handleNextSlide = () => {
    setCounter((prevCount) => {
      return prevCount >= data.length - 1 ? 0 : prevCount + 1;
    });
  };
  const handlePrevSlide = () => {
    setCounter((prevCount) => {
      return prevCount === 0 ? data.length - 1 : prevCount - 1;
    });
  };

  return (
    <div className={styles.tourCardContainer}>
      <div className={styles.tourCard}>
        <h3>Get Started @AerialBorne</h3>
        {/* Close ICon */}
        <FontAwesomeIcon className={styles.closeTourBtn} icon={faXmark} onClick={()=>{
            setShowTour(false)
        }} />

        <div className={styles.tourCardBody}>
          <div className={styles.tourCardImg}>
            <img src={data[counter].image} alt="" />
          </div>
          <div className={styles.tourCardContent}>
            <h3 className={styles.tourCardHeading}>{data[counter].title}</h3>
            <p>
            {data[counter].description}
            </p>
            <div className={styles.tourBtnContainer}>
            <button
              className={styles.tourNavigateBtn}
              onClick={() => {
                navigate(`${data[counter].path}`);
              }}
            >
              Add Your {data[counter].btnData}
            </button>
            </div>
          </div>
        </div>

        <div
          title="Previous"
          className={`${styles.leftSlider} ${styles.sliderBtn} `}
          onClick={handleNextSlide}
        >
          {/* <img src={leftSwipeImg} alt="" /> */}
          <FontAwesomeIcon className={styles.navigationIcons} icon={faChevronLeft} />
        </div>
        <div
          title="Next"
          className={`${styles.rightSlider} ${styles.sliderBtn} `}
          onClick={handlePrevSlide}
        >
          {/* <img src={rightSwipeImg} alt="" /> */}
          <FontAwesomeIcon className={styles.navigationIcons} icon={faChevronRight} />
        </div>
      </div>
    </div>
  );
}
