import { useState, useCallback } from 'react';
import axiosClient from '../../Utils/Axios/AxiosClient';
import toast from 'react-hot-toast';

import { useSelector } from 'react-redux';

const useCertificate = () => {
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const userState = useSelector((state) => state.partner);

  const getAllCertificates = useCallback(async () => {
    setLoading(true);
    try {
      const result = await axiosClient.get(`/${userState.user.user_id}/certificates`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setCertificates(result.data.certificates);
      toast.success('Certificates fetched successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to fetch certificates. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  const postCertificate = useCallback(async (certificateData) => {
    setLoading(true);
    try {
      const result = await axiosClient.post(`/${userState.user.user_id}/certificates`, certificateData,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      // setCertificates((prevCertificates) => [...prevCertificates, result.data.certificates]);
      toast.success('Certificate added successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to add certificate. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  const updateCertificate = useCallback(async (certificateId, updatedData) => {
    setLoading(true);
    try {
      const result = await axiosClient.get(`${userState.user.user_id}/certificates`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setCertificates((prevCertificates) =>
        prevCertificates.map((certificate) =>
          certificate.id === certificateId ? result.data : certificate
        )
      );
      toast.success('Certificate updated successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to update certificate. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteCertificate = useCallback(async (certificateId) => {
    setLoading(true);
    try {
      await axiosClient.delete(`/${userState.user.user_id}/certificates/${certificateId}`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setCertificates((prevCertificates) =>
        prevCertificates.filter((certificate) => certificate.certificate_id !== certificateId)
      );
      toast.success('Certificate deleted successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to delete certificate. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    certificates,
    loading,
    error,
    getAllCertificates,
    postCertificate,
    updateCertificate,
    deleteCertificate,
  };
};

export default useCertificate;
