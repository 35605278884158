import React from 'react';
import styled from 'styled-components';

const AvatarContainer = styled.div`
  width: ${(props) => props.width || '80px'};
  height: ${(props) => props.height || '80px'};
  border-radius: 50%;
  transition: all 0.2s;
  overflow: hidden;
  background-color: #000; 
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.customStyle};
  ${(props) => props.hover && 'cursor: pointer;'}

  &:hover {
    outline: ${(props) => props.hover ? '5px solid rgb(194, 194, 194)' : 'none'};
  }
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Initials = styled.span`
  font-size: ${(props) => props.fontSize || '24px'};
  color: #fff; 
`;

const Avatar = ({ imageUrl, altText, name, customStyle, setShowProfileDetails, width, height, fontSize, hover }) => {
  const getInitials = () => {
    return name
      .split(' ')
      .map((part) => part[0])
      .join('')
      .toUpperCase();
  };

  // Conditionally apply onClick event handler if setShowProfileDetails is passed
  const handleClick = () => {
    if (setShowProfileDetails) {
      setShowProfileDetails(true);
    }
  };

  return (
    <AvatarContainer
      customStyle={customStyle}
      onClick={handleClick}
      width={width}
      height={height}
      fontSize={fontSize}
      hover={hover} 
    >
      {imageUrl ? (
        <AvatarImage src={imageUrl} alt={altText} />
      ) : (
        <Initials>{getInitials()}</Initials>
      )}
    </AvatarContainer>
  );
};

export default Avatar;
