import styles from "./deletebox.module.css";
const DeleteBox = ({ id, deleteFunc, setDelConfirmation, deleteName }) => {
  return (
    <div className={styles.deleteBoxContainer}>
      <div className={styles.deleteBox}>
        <h2>Are you sure you want to delete {deleteName} ?</h2>

        <div className={styles.deleteBoxBtns}>
          <button
            className={styles.cancelBtn}
            onClick={() => {
              setDelConfirmation(false);
            }}
          >
            Cancel
          </button>
          <button
            className={styles.deleteBtn}
            onClick={() => {
              deleteFunc(id);
              setDelConfirmation(false);
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteBox;
