import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import "./DashboardLayout.css";
import Toolbar from "../../../Component/toolbar/Toolbar";
import Sidebar from "../../../Component/sidebar/Sidebar";
import Footer from "../../../Component/footer/Footer";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const DashboardLayout = () => {
  const [opensidebar, setopensidebar] = useState(false);
  const userState = useSelector((state) => state.partner);
  const [userData, setUserdata] = useState(null);

  useEffect(() => {
    setUserdata(userState.user);
  }, [userState]);

  return (
    <div className="dashboardLayout">
      <nav className="navbar">
        <Toolbar
          setopensidebar={setopensidebar}
          sidebaropen={opensidebar}
          userData={userData}
        />
      </nav>
      <section className="main-section">
        {opensidebar && (
          <aside className="mobileviewsidebar">
            <Sidebar userData={userData} />
            <FontAwesomeIcon
              className="closeIcon"
              icon={faXmark}
              onClick={() => {
                setopensidebar(false);
              }}
            />
          </aside>
        )}
        <aside className="sidebar">
          <Sidebar userData={userData} />
        </aside>
        <main className="dynamic-content">
          <Outlet userData={userData}/>
          <footer>
            <Footer />
          </footer>
        </main>
      </section>

      {/* <footer>
        <Footer />
      </footer> */}
    </div>
  );
};

export default DashboardLayout;
