import React from "react";
import "./authLayout.css";
import { Outlet } from "react-router-dom";
import LogoNavbar from "../../../Component/logonavbar/LogoNavbar";
import ImageCard from "../../../Component/ImageCard/ImageCard";



const AuthLayout = () => {
  return (
    <div className="authLayout">

      {/* <nav className="authnavbar">
        <LogoNavbar />
      </nav> */}
      <section className="mainContent">
        <aside className="imageContainer">
          <ImageCard />
        </aside>
        <main className="authMainSection">
          <Outlet />
        </main>
      </section>

      
    </div>
  );
};

export default AuthLayout;
