import styled from "styled-components";


export const Button = styled.button`
  align-items: center;
  background-color: #0a66c2;
  border: 0;
  border-radius: 10rem; 

  cursor: pointer;
  display: inline-flex;

  font-size: 1rem; 
  font-weight: 600;
  justify-content: center;
  line-height: 3.2rem;
  margin: 0.5rem 0; 
  min-height: 2.4rem; 
  min-width: 0;
  overflow: hidden;
  height: 35px;
  padding: 0 1.2rem; 
  text-align: center;
  background-color: ${(props) => (props.filled ? props.bg || "#000" : "#fff")};
  color: ${(props) => (props.filled ? props.color || "#fff" : "#000")};
  border: ${(props) => (props.filled ? "none" : "0.1rem solid #000")};
`;

