import React from 'react'
import style from "./AddFlyingSpotLocationImage.module.css"
import drone from "../../../Assets/images/drone.jpeg"

const AddFlyingSpotLocationImage = () => {
  return (
    <div className={style.box} >
    <h1>Add new Flying Spot </h1>
    <div className={style.container}>
        <h1>Enter Spot Details</h1>
    <div className={style.flyingSpotFormContainer}>
   
   <div className={style.stack}>
   <div className={style.inputbox}>
    <label>Name of SPOT </label>
    <input placeholder='Enter name of spot'></input>
   </div>
   <div className={style.uploaderContainer}>
    <p>Upload files</p>
    <div className={style.uploaderImageBox}></div>
    <div className={style.stackRight}>
        <div className={style.cancelBtn}>cancel</div>
        <div className={style.uploadBtn}>upload</div>
    </div>
   </div>
   </div>
  

<div className={style.footer}>

    <div className={style.imageContainer}><img src={drone}></img></div>
    <div className={style.stackRight}>
        <div className={style.cancelBtn}>cancel</div>
        <div className={style.uploadBtn}>save</div>
    </div>
</div>

        </div>

    </div>

  </div>
  )
}

export default AddFlyingSpotLocationImage