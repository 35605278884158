import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import styles from "./sectionLayout.module.css";

import Breadcrumb from "../../../Component/breadCrumb/BreadCrumb";
import { Button } from "../../../Component/Button/Button";

const SectionLayout = () => {
  const location = useLocation();

  const [path, setPath] = useState("");
  const [btnName, setBtnName] = useState("");

  useEffect(() => {
    switch (location.pathname) {
      case "/dashboard/drone":
        setPath(location.pathname + "/adddrone");
        setBtnName("Add Drone");
        break;
      case "/dashboard/equipment":
        setPath(location.pathname + "/addequipment");
        setBtnName("Add Equipment");
        break;
      case "/dashboard/certificate":
        setPath(location.pathname + "/addcertificate");
        setBtnName("Add Certificate");
        break;
      case "/dashboard/myexperience":
        setPath(location.pathname + "/addexperience");
        setBtnName("Add Experience");
        break;
      case "/dashboard/showreel":
        setPath(location.pathname + "/addshowreel");
        setBtnName("Add Showreel");
        break;
      case "/dashboard/service":
        setPath(location.pathname + "/addservice");
        setBtnName("Add Service");
        break;
      default:
        setPath("");
        setBtnName("");
    }
  }, [location.pathname]);

  return (
    <div className={styles.details}>
      <div className={styles.headingflex}>
        <Breadcrumb />
        {path && (
          <Link to={path}>
            <Button>{btnName}</Button>
          </Link>
        )}
      </div>

      <Outlet />
    </div>
  );
};

export default SectionLayout;
