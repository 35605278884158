import React, { useState } from "react";
import style from "../dashboardFormStyle/dashboardFormStyle.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import useCustomHookS3 from "../../../../CustomHook/AvatarPreview/useCustomHookS3";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useCertificate from "../../../../CustomHook/CertificateHook/useCertificate";
import AuthFailed from "../../../../Component/BrokenPage/AuthFailed";
import BrokenPage from "../../../../Component/BrokenPage/BrokenPage";

const CertificateForm = () => {
  const navigate = useNavigate();
  const { postCertificate } = useCertificate();
  const { uploadFileInS3, imageUrl, error } = useCustomHookS3();
  // const [imageUrl, setImageUrl] = useState(null);
  const [formData, setFormData] = useState({
    certificate_name: "",
    certificate_issued_by: "",
    // equipment_category:"",
    image: null,
    droneMediaUrl: "",
  });
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      setFormData((prevData) => ({
        ...prevData,
        droneMediaUrl: imageUrl,
      }));
      setUploading(false);
      setUploadSuccess(true);
    }
  }, [imageUrl]);

  const handleSubmit = (event) => {
    try {
      event.preventDefault();
      postCertificate(formData);
      navigate("/dashboard/certificate");
    } catch (error) {
      console.log("Error Form Submission!");
    }
  };

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "image") {
      const file = files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFormData((prevData) => ({
            ...prevData,
            image: file,
            droneMediaUrl: reader.result,
          }));
        };
        reader.readAsDataURL(file);
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCancel = () => {
    setFormData((prevData) => ({
      ...prevData,
      image: null,
      droneMediaUrl: "",
    }));
  };

  const handleUpload = () => {
    if (formData.image) {
      setUploading(true);
      const key = `${Date.now()}${formData.image.name}`;
      const contentType = formData.image.type;
      uploadFileInS3(formData.image, key, contentType);
    }
  };

  return (
    <div className={style.dashboardFormContainerWrapper}>
      {error ? (
        error.response && error.response.status === 401 ? (
          <AuthFailed />
        ) : (
          <BrokenPage />
        )
      ) : (
        <div className={style.dashboardFormContainer}>
          <form className={style.FormContainer} onSubmit={handleSubmit}>
            <h2 className={style.dashboardFormContinerHeading}>
              Add New Certificate
            </h2>
            <div className={style.dashboardFormInputContainer}>
              <input
                className={style.dashFormContainerInput}
                name="certificate_name"
                type="text"
                value={formData.certificate_name}
                placeholder="Write Certificate Name"
                onChange={handleInputChange}
                required
              />
              <input
                className={style.dashFormContainerInput}
                name="certificate_issued_by"
                type="text"
                value={formData.certificate_issued_by}
                placeholder="Enter Certificate Issuer"
                onChange={handleInputChange}
                required
              />
              <div className={style.formField}>
                <div className={style.DragUploader}>
                  <div className={style.DragUploaderHeading}>
                    Upload file of sample work
                  </div>
                  {uploading ? (
                    <div>Uploading...</div>
                  ) : (
                    <div className={style.DragUploaderImageContainer}>
                      <input
                        name="image"
                        type="file"
                        accept="image/*"
                        onChange={handleInputChange}
                      />
                      {formData.droneMediaUrl && (
                        <>
                          <img
                            src={formData.droneMediaUrl}
                            alt="Preview"
                            className={style.previewImage}
                          />
                        </>
                      )}
                      {!formData.droneMediaUrl && (
                        <FontAwesomeIcon
                          className={style.uploadIcon}
                          icon={faCloudArrowUp}
                        />
                      )}
                    </div>
                  )}
                  <div className={style.draguploadbutton}>
                    <button
                      type="button"
                      className={style.dashboardFormCancelButton}
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    {!uploading && (
                      <button
                        type="button"
                        className={style.dashboardFormSaveButton}
                        onClick={handleUpload}
                        disabled={uploadSuccess}
                      >
                        {uploadSuccess ? "Uploaded" : "Upload"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={style.btnContainer}>
              {uploadSuccess && (
                <button
                  className={style.dashboardFormSubmitButton}
                  type="submit"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default CertificateForm;
