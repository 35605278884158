import React from "react";
import style from "./AddFlyingSpot.module.css";
import map from "../../../Assets/images/drone.png";
import drone from "../../../Assets/images/drone.jpeg";

const AddFlyingSpot = () => {
  return (
    <div className={style.flyingSpotBox}>
      <div className={style.Vstack}>
        <h1>Discover and Soar:Flying Spots Navigator</h1>
        <p>
          explore a curated map of drone friendly-locations , find real time
          real updates on spot status and contribute to our growing community of
          aviators
        </p>
        <div className={style.Hstack}>
          <input placeholder="search a spot near your location"></input>
          <button>Add a New Spot</button>
        </div>
      </div>

      <div className={style.flyingSpotImage}>
        <img src={map} alt=""></img>
      </div>
      <div className={style.text}>Recently Added</div>
      <div className={style.allFlyingSpotContainer}>
        <div className={style.flyingspotCard}>
          <div className={style.flyingspotCardImage}>
            <img src={drone} alt=""></img>
          </div>
          <div className={style.flyingspotCardBody}>
            <div className={style.flyingspotCardIcon}></div>
            <div className={style.flyingSpotCardInfo}>
              <h1>Delhi Dunes</h1>
              <center>
                <h2>Delhi</h2>
                <p>35 Mins Away</p>
              </center>
              <div className={style.follow}>
                <button>Follow</button>
              </div>
              <div className={style.experience}>
                <button className={style.flyingspotcardExperineceButton}>
                  Add Your Experience
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={style.flyingspotCard}>
          <div className={style.flyingspotCardImage}>
            <img src={drone}></img>
          </div>
          <div className={style.flyingspotCardBody}>
            <div className={style.flyingspotCardIcon}></div>
            <div className={style.flyingSpotCardInfo}>
              <h1>Delhi Dunes</h1>
              <center>
                <h2>Delhi</h2>
                <p>35 Mins Away</p>
              </center>
              <div className={style.follow}>
                <button>Follow</button>
              </div>
              <div className={style.experience}>
                <button className={style.flyingspotcardExperineceButton}>
                  Add Your Experience
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFlyingSpot;
