// flyingspotSlice.js
import { createSlice } from '@reduxjs/toolkit';

const FlyingSpotSlice = createSlice({
  name: 'flyingspot',
  initialState: {
   
  },
  reducers: {
   
  },
});

export const { /* action creators */ } = FlyingSpotSlice.actions;
export default FlyingSpotSlice.reducer;
