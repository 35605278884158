import React, { useState, useEffect } from "react";
import styles from "./brokenPage.module.css";
import Lottie from "lottie-react";
import error500Json from "../../Assets/lottie/500Error.json";

const BrokenPage = () => {
  const [countdown, setCountdown] = useState(20);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      window.location.reload();
    }
  }, [countdown]);

  return (
    <div className={styles.brokenpageContainer}>
      <div className={styles.brokenPage}>
        <h1>Oops! Something Went Wrong...</h1>
        <Lottie
          animationData={error500Json}
          autoplay={true}
          className={styles.lottieAnimation}
        />
        <p>
          We apologize for the inconvenience. Our team is working diligently to
          resolve the issue.
        </p>
        <p>
          If the issue persists for longer, please try again after some time. We
          sincerely apologize for any inconvenience caused.
        </p>
        <button
          className={styles.BrokenPageBtn}
          onClick={() => window.location.reload()}
        >
          Retry Now
        </button>
        <p>*This page will refresh automatically in {countdown} seconds.</p>
      </div>
    </div>
  );
};

export default BrokenPage;
