import React from "react";
import "./imageCard.css";
import image from "../../Assets/login/logoPage.png";

const ImageCard = () => {
  return (
    <div className="left">

      <div className="img-cont">
        <img src={image} alt="" />     
      </div>
      <h4>
        <span>AerialBorne</span> helps you to showcase your skills and experience to potential clients.
      </h4>
    </div>
  );
};

export default ImageCard;
