import { useState, useCallback } from 'react';
import axiosClient from '../../Utils/Axios/AxiosClient';
import toast from 'react-hot-toast'; // Import react-hot-toast
import { useSelector } from 'react-redux';

const useDroneEquipment = () => {
  const [droneEquipments, setDroneEquipments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const userState = useSelector((state) => state.partner);

  // Fetch all drone equipments
  const getAllDroneEquipments = useCallback(async () => {
    setLoading(true);
    try {
      const result = await axiosClient.get(`/${userState.user.user_id}/equipments`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setDroneEquipments(result.data.equipments);
      toast.success('Drone equipments fetched successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to fetch drone equipments. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  // Post a new drone equipment
  const postDroneEquipment = useCallback(async (droneEquipmentData) => {
    setLoading(true);
    try {
      const result = await axiosClient.post(`/${userState.user.user_id}/equipments`, droneEquipmentData,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      toast.success('Drone equipment added successfully!');
      // setDroneEquipments((prevDroneEquipments) => [...prevDroneEquipments, result.data]);
    } catch (error) {
      setError(error);
      toast.error('Failed to add drone equipment. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  // Update a drone equipment
  const updateDroneEquipment = useCallback(async (droneEquipmentId, updatedData) => {
    setLoading(true);
    try {
      const result = await axiosClient.put(`/${userState.user.user_id}/equipments`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setDroneEquipments((prevDroneEquipments) =>
        prevDroneEquipments.map((droneEquipment) =>
          droneEquipment.id === droneEquipmentId ? result.data : droneEquipment
        )
      );
      toast.success('Drone equipment updated successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to update drone equipment. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  // Delete a drone equipment
  const deleteDroneEquipment = useCallback(async (droneEquipmentId) => {
    setLoading(true);
    try {
      await axiosClient.delete(`/${userState.user.user_id}/equipments/${droneEquipmentId}`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setDroneEquipments((prevDroneEquipments) =>
        prevDroneEquipments.filter((droneEquipment) => droneEquipment.equipment_id !== droneEquipmentId)
      );
      toast.success('Drone equipment deleted successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to delete drone equipment. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    droneEquipments,
    loading,
    error,
    getAllDroneEquipments,
    postDroneEquipment,
    updateDroneEquipment,
    deleteDroneEquipment,
  };
};

export default useDroneEquipment;
