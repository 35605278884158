import React from "react";
import "./footer.css";
import youtube from "./images/youtube.png";
import instagram from "./images/instagram.png";
import facebook from "./images/facebook.png";
import linkedin from "./images/linkedin.png";
import pushpin from "./images/push-pin.png";
import skills from "./images/skills.png";
// import logoicon from "./images/logo-icon.png";
import logoLight from '../../Assets/logo/abLight2.png'
import logofont from "./images/font.png";
const Footer = () => {
	return (
		<div className="outer">
			{/* <div className="inner"> */}
			<div className="content">
				<div className="logo-cont">
					 <img src={logoLight} className="logo-img" alt="" />
				</div>
			</div>

			{/* </div> */}
			<div className="company-media">
				
				<div className="main-social-icon">
					<a title="AerialBorne | Linkedin" href="https://www.linkedin.com/company/aerialborne/" target="_blank" rel="noreferrer"><img src={linkedin} alt="" /></a>
				</div>
				<div className="main-social-icon">
					<a title="AerialBorne | Instagram" href="https://www.instagram.com/aerial.borne?igsh=N2doMWYzemdjNGth" target="_blank" rel="noreferrer" ><img src={instagram} alt="" /></a>
				</div>
			</div>

			{/* Changed Order */}
			<div className="bottom-bar">
				<ul className="company-info">
					{/* <h4>© 2021-2023 Aerialborne, Inc.</h4> */}
					<h4>© 2024 AerialBorne, Inc.</h4>
				</ul>
			</div>
		</div>
	);
};

export default Footer;
