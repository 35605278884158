import React, { useState } from 'react'
import Toolbar from '../../Component/toolbar/Toolbar'
import Sidebar from '../../Component/sidebar/Sidebar'
import { Outlet } from 'react-router-dom'
import Footer from '../../Component/footer/Footer'

const FlyingSpotLayout = () => {
    const [opensidebar,setopensidebar]=useState(false)
    return (
        <>
        <div className='dashboardLayout'>
    
          <nav  className='navbar'>
            <Toolbar setopensidebar={setopensidebar} sidebaropen={opensidebar}/>
          </nav>
          <section className='main-section'  >
            {opensidebar && <aside className='mobileviewsidebar' >
            <Sidebar/>
            </aside>}
            <aside className='sidebar' >
            <Sidebar/>
            </aside>
           <main className='dynamic-content' >
           <Outlet/>
           </main>
         
          </section>
    
        
     <footer>
     <Footer/> 
     </footer>
        </div>
       
        </>
      )
}

export default FlyingSpotLayout