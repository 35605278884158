import React from 'react'
import style from "./PartnerSocialCreatepost.module.css"
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const PartnerSocialCreatepost = () => {
  return (


    <div className={style.box}> 
    <div className={style.createPostCard}>

    <header>
    <div className={style.headingTitle}> <h1> Create a Post  </h1>
</div> 

  <div className={style.closeIcon}>

  <ClearOutlinedIcon />

  </div>
   
  </header>
   

  <div className={style.createPostCardBody}>


<div className={style.inputBox}>
<label>Share your Journey</label>
<textarea placeholder='Type about your day, flying sessions, project completed' > </textarea>

</div>
   
    
    
    

  

    <div className={style.uploadAssets}>

   <h3> Upload assets </h3>
   <div className={style.uploadImageBox}>

   <div className={style.uploadImageIcon}>
       
   </div>
   <div className={style.uploadedImage}>
    <img src=''></img>
   </div>
   </div>
   </div>
   
   <div className={style.button} >
   <button className='boxBtn' type='button'> Submit </button>     
   </div>

   </div>
   </div>
   </div>
  )
}

export default PartnerSocialCreatepost