import React, { useEffect, useState } from "react";
import styles from "./completeProfile.module.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../../Assets/logo/abdark2.png";
import toast from "react-hot-toast";
import usePartner from "../../../CustomHook/PartnerHook/usePartner";
import AuthFailed from "../../../Component/BrokenPage/AuthFailed";
import BrokenPage from "../../../Component/BrokenPage/BrokenPage";
export default function CompleteProfile() {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.partner);
  const { updateUserData, loading, error } = usePartner();
  useEffect(() => {
    console.log("User Number:", userState.user );
    if (!userState.user || userState.user.contact_number) {
      console.log("Terminate Page!");
      navigate("/dashboard");
    }
  }, [userState]);

  const [formData, setFormData] = useState({
    username: userState.user && userState.user.username,
    email: userState.user && userState.user.email,
    profile_image_url: userState.user && userState.user.profile_image_url,
    contact_number: "",
    address: "",
  });

  const handleInputChange = (e) => {
    if (e.target.id === "contact_number") {
      if (!/^\d*$/.test(e.target.value)) {
        toast.error("Please type your phone number correctly!");
        return;
      }
    }

    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    await updateUserData(formData);
    navigate("/dashboard/skills");
  };

  return loading ? (
    <p>Loading...</p>
  ) : error ? (
    error.response && error.response.status === 401 ? (
      <AuthFailed />
    ) : (
      <BrokenPage />
    )
  ) : (
    <div className={styles.completeProfileContainer}>
      <div className={styles.logo}>
        <img src={logo} alt="AerialBorne" />
      </div>
      <div className={styles.completeProfileContent}>
        <h1 className={styles.completeProfileHeader}>Complete Your Profile</h1>

        <form onSubmit={handleSubmit}>
          <div className={styles.inputContainer}>
            <label htmlFor="username">Enter Your Name</label>
            <input
              type="text"
              id="username"
              value={formData.username}
              placeholder="Eg. John Doe"
              onChange={handleInputChange}
              required
            />
          </div>

          <div className={styles.inputContainer}>
            <label htmlFor="email">Enter Your Email</label>
            <input
              type="email"
              id="email"
              value={formData.email}
              placeholder="Eg. johndoe@gmail.com"
              readOnly
              required
            />
          </div>

          <div className={styles.inputContainer}>
            <label htmlFor="contact_number">Enter Your Mobile Number</label>
            <div className={styles.pgNumContainer}>
              <span>+91</span>
              <input
                type="text"
                id="contact_number"
                value={formData.contact_number}
                placeholder="Eg. 9876543210"
                minLength={10}
                maxLength={10}
                min={0}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className={styles.inputContainer}>
            <label htmlFor="address">Enter Your address</label>
            <input
              type="text"
              id="address"
              value={formData.address}
              placeholder="Eg. New Delhi"
              onChange={handleInputChange}
              required
            />
          </div>

          <div className={styles.btnContainer}>
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}
