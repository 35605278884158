import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import styles from "../dashboardStyles/dashboardStyle.module.scss";
import loadingImg from "../../../../Assets/images/noImgFound.png";
import { useNavigate } from "react-router-dom";
import DeleteBox from "../../../../Component/DeleteBox/DeleteBox";

const ExperienceItemCard = ({
  // id,
  // imageSrc,
  // title,
  // company,
  // description,
  // toggleContent,
  duration,
  experience,
  deleteExperience,
}) => {
  const [delConfirmation, setDelConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState("");
  const navigate = useNavigate();
  const handleEdit = () => {
    navigate("addexperience", { state: { experience } });
  };

  return (
    <>
      <div className={styles.dashboardExperienceCardItem}>
        <div className={styles.expCustomiseBtn}>
          <FontAwesomeIcon
            className={styles.experiencEditIcon}
            icon={faPenToSquare}
            onClick={() => {
              handleEdit();
            }}
            title="Edit"
          />
          <FontAwesomeIcon
            icon={faTrash}
            className={styles.expDelIcon}
            onClick={() => {
              setDeleteId(experience.exp_id);
              setDeleteName(experience.exp_title);
              setDelConfirmation(true);
            }}
            title="Delete"
          />
        </div>
        <div className={styles.dashboardExperienceCardItemImage}>
          <img
            className={styles.experienceImage}
            src={experience.media_url}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = loadingImg;
            }}
            alt=""
          />
        </div>
        <div className={styles.dashboardExperienceCardItemDetail}>
          <h3 className={styles.dashboardExperienceCardItemTitle}>
            {experience.exp_title}
          </h3>
          <h5 className={styles.dashboardExperienceCardItemCompany}>
            {experience.company}
          </h5>
          <p className={styles.dashboardExperienceCardItemDuration}>
            {duration}
          </p>
          {experience.exp_desc && (
            <div>
              <p className={styles.dashboardExperienceCardItemDescription}>
                {experience.exp_desc}
              </p>
            </div>
          )}
        </div>
      </div>
      {delConfirmation && (
        <DeleteBox
          deleteFunc={deleteExperience}
          id={deleteId}
          deleteName={deleteName}
          setDelConfirmation={setDelConfirmation}
        />
      )}
    </>
  );
};

export default ExperienceItemCard;
