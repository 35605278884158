import React, { useEffect } from "react";
import ExperienceItemCard from "./ExperienceItemCard";
import styles from "../dashboardStyles/dashboardStyle.module.scss";
import useExperience from "../../../../CustomHook/ExperienceHook/useExperience";
import noDataSvg from "../../../../Assets/svg/noData.svg";
import BrokenPage from "../../../../Component/BrokenPage/BrokenPage";
import AuthFailed from "../../../../Component/BrokenPage/AuthFailed";
import { useSelector } from "react-redux";
const MyExperience = () => {
  const { experiences, loading, error, getAllExperiences, deleteExperience } =
    useExperience();
  const userState = useSelector((state) => state.partner);

  useEffect(() => {
    if (userState && userState.user) {
      getAllExperiences();
    }
  }, []);

  const formatDuration = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const diffInMilliseconds = Math.abs(end - start);
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const days = Math.floor(diffInMilliseconds / millisecondsPerDay);

    const diffInMonths =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth());
    const years = Math.floor(diffInMonths / 12);
    const months = diffInMonths % 12;

    return `${years}yrs ${months}months ${days}days`;
  };

  return (
    <div className={styles.dashboardExperienceCard}>
      <div className={styles.dashboardExperienceCardHeading}>
        <h3 className="n">My Experience</h3>
      </div>
      <div className={styles.experienceContainer}>
        {loading && <p>Loading...</p>}
        {error &&
          (error.response && error.response.status === 401 ? (
            <AuthFailed />
          ) : (
            <BrokenPage />
          ))}
        {experiences.length > 0 ? (
          experiences.map((experience) => (
            <ExperienceItemCard
              key={experience.exp_id}
              duration={`${new Date(
                experience.exp_st
              ).toLocaleDateString()} - ${new Date(
                experience.exp_et
              ).toLocaleDateString()} (${formatDuration(
                experience.exp_st,
                experience.exp_et
              )})`}
              experience={experience}
              deleteExperience={deleteExperience}
            />
          ))
        ) : (
          <div className={styles.noDataFound}>
            <img src={noDataSvg} alt="No Data" />
          </div>
        )}
      </div>
    </div>
  );
};

export default MyExperience;
