import styles from "./brokenPage.module.css";
import Lottie from "lottie-react";
import error500Json from "../../Assets/lottie/500Error.json";
import { useNavigate } from "react-router-dom";

const AuthFailed = () => {
    const navigate = useNavigate()
  return (
    <div className={styles.brokenpageContainer}>
      <div className={styles.brokenPage}>
        <h1>Unauthorised User! Please login to Continue!</h1>
        <Lottie
          animationData={error500Json}
          autoplay={true}
          className={styles.lottieAnimation}
        />
        <button
          className={styles.BrokenPageBtn}
          onClick={() => {
            localStorage.clear();
            navigate('/');
          }}
        >
          Go To Login Page
        </button>
        <p>*Please login again To continue.</p>
      </div>
    </div>
  );
};

export default AuthFailed;