import React from 'react'
import style from "./PartnerSocialGroup.module.css"

import group from "../../../Assets/images/groups.jpeg"

const PartnerSocialGroup = () => {
    return (
        <div className={style.Box}>
     <header>
         <h1>Groups</h1>
        <button>Create a Group</button>
     </header>
     
     <div className={style.groupContainer}>
     <div className={style.groupCard}>
     <div className={style.groupCardBody}>
     
         <div className={style.leftside}><img src={group}></img></div>
         <div className={style.rightside}>
             <h1>FPV mumbai</h1>
             <h2>jay kishor-admin</h2>
             <div className={style.stack}>
                 <div className={style.ratingbox}>
                     <h1>Ratings</h1>
                     <span>4.5</span>
                     <div><img ></img></div>
                 </div>
                 <div className={style.followerbox}>
                 <h1>followers</h1>
                     <span>1000+</span>
                     <div><img></img></div>
                 </div>
             </div>
         </div>
     </div>
     
     <div className={style.groupCardFooter}>
         <h1>About</h1>
         <p>
     Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
         <button className={style.followBtn}>follow</button>
     </div>
     </div>
     
     </div>
     
        </div>
       )
}

export default PartnerSocialGroup