import React, { useEffect, useState } from "react";
import styles from "./FeedbackForm.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import axiosClient from "../../Utils/Axios/AxiosClient";
import AuthFailed from "../BrokenPage/AuthFailed";
import BrokenPage from "../BrokenPage/BrokenPage";
import sendTelegramMessage from '../../services/Telegram/TelegramService'
const ratingsData = [
  { rating: 0, description: "Very Dissatisfied" },
  { rating: 1, description: "Dissatisfied" },
  { rating: 2, description: "Slightly Dissatisfied" },
  { rating: 3, description: "Neutral" },
  { rating: 4, description: "Satisfied" },
  { rating: 5, description: "Very Satisfied" },
];

export default function FeedbackForm({ setOpenFeedBackForm }) {
  const userState = useSelector((state) => state.partner);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    partnerName: userState.user && userState.user.username,
    userEmail: userState.user && userState.user.email,
    contact_number: userState.user && userState.user.contact_number,
    rating: null,
    feedback: "",
  });

  // Send Telegram Msg
  // const sendTelegramMessage = async (token, chatId, message) => {
  //   const url = `https://api.telegram.org/bot${token}/sendMessage`;
  //   const body = JSON.stringify({
  //     chat_id: chatId,
  //     text: message,
  //   });

  //   const response = await fetch(url, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: body,
  //   });

  //   if (!response.ok) {
  //     throw new Error("Failed to post feedback!");
  //   }

  //   return response.json();
  // };

  const handleSubmit = async () => {
    // Remove newline characters from feedback
    const cleanFeedback = formData.feedback.replace(/\n/g, " ");
    if (formData.rating === null || formData.rating < 0 || formData.rating > 5) {
      toast.error("Please select a rating from 0 to 5!");
      return;
    }
    const message = `
    🌟🌟🌟🌟🌟 *User Feedback* 🌟🌟🌟🌟🌟\n
    Username: ${formData.partnerName}
    Email: ${formData.userEmail}
    Phone Number: ${formData.contact_number}
    Rating: ${formData.rating} out of 5 (${
      ratingsData[formData.rating].description
    })
    Feedback: ${cleanFeedback}
    `;

    setLoading(true);
    try {
      const token = process.env.REACT_APP_TELEGRAM_TOKEN;
      const chat_id = process.env.REACT_APP_TELEGRAM_FEEDBACK_CHATID;
      await sendTelegramMessage(token, chat_id, message);
      setOpenFeedBackForm(false);
      toast.success("Thank you for your feedback!");
    } catch (error) {
      toast.error("Failed to submit feedback! Please try again after some time!");
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return error ? (
    error.response && error.response.status === 401 ? (
      <AuthFailed />
    ) : (
      <BrokenPage />
    )
  ) : (
    <div className={styles.feedbackFormContainer}>
      <div className={styles.feedbackForm}>
        <FontAwesomeIcon
          className={styles.closeFeedbackForm}
          icon={faXmark}
          onClick={() => {
            setOpenFeedBackForm(false);
          }}
        />
        <div className={styles.ratingContainer}>
          <h2>
            How likely are you to recommend our company to a friend or
            colleague?
          </h2>

          <div className={styles.ratingBox}>
            <ul className={styles.ratings}>
              {ratingsData.map((rating) => (
                <li
                  title={rating.description}
                  key={rating.rating}
                  style={{
                    backgroundColor: `rgba(${255 - rating.rating * 50}, ${
                      100 + rating.rating * 30
                    }, 0, 1)`,
                  }}
                  className={`${
                    formData.rating === rating.rating ? styles.activeRating : ""
                  }`}
                  onClick={() => {
                    setFormData({
                      ...formData,
                      rating: rating.rating,
                    });
                  }}
                >
                  {rating.rating}
                </li>
              ))}
            </ul>
            <p className={styles.ratingNote}>
              <span>😠 0 - Very Dissatisfied</span>
              <span>😊 5 - Very Satisfied</span>
            </p>

            <div className={styles.feedbackText}>
              <textarea
                name=""
                placeholder="What can we do to improve our service?"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    feedback: e.target.value,
                  });
                }}
                value={formData.feedback}
              ></textarea>
            </div>

            <div className={styles.feedbackBtn}>
              <button onClick={handleSubmit}>
                {loading ? "Submitting...!" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
