import React from 'react'
import styles from '../dashboardFormStyle/dashboardFormStyle.module.scss'



const PartnerEditSocialIcon = () => {
  return (
    <div className={styles.outerProfile}>
    <div className={styles.innerProfile}>
        
        <div className={styles.editProfile}>
            
            <div className={styles.profileCont}>
                
                <div className={styles.profileIcon}>
                    <img src="assets/user.png"/>
                </div>
                <div className={styles.editIcon}>
                    <img src="assets/edit-avatar.png"/>
                </div>
            </div>

            <div className={styles.inputCont}>
                
                <div className={styles.username}>
                    <label htmlFor="username">Facebook:</label>
                    <input type="text" id="username"/>
                </div>
                <div className={styles.mobileNumber}>
                    <label htmlFor="mobile_number">Instagram:</label>
                    <input type="text" id="mobile_number"/>
                </div>
                <div className={styles.email}>
                    <label htmlFor="email">Linkedin:</label>
                    <input type="text" id="email"/>
                </div>
               

                <div className={styles.profileButtonCont}>
                        <button className={styles.saveProfileBtn} type="button">Save</button>
                </div>
                
            </div>

        </div>

    </div>
</div>

  )
}

export default PartnerEditSocialIcon