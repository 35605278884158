import { useState, useCallback, useEffect } from "react";
import axiosClient from "../../Utils/Axios/AxiosClient";
import { useDispatch, useSelector } from "react-redux";
import { logInPartner } from "../../Redux/Partner/PartnerAuthSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const usePartner = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [profilePgData, setProfilePgData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userState = useSelector((state) => state.partner);

  const fetchUserData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await axiosClient.get(
        `/private/${userState.user.user_id}/profile`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(
        logInPartner({
          user: result.data.partners[0],
          token: result.data.token,
        })
      );
      setUserData(result.data.partners[0]);
      toast.success("User Details Synced!");
    } catch (error) {
      setError(error);
      toast.error("Failed to fetch user data.");
    } finally {
      setLoading(false);
    }
  }, []);

  const checkUser = useCallback(async (userInfo) => {
    setLoading(true);
    try {
      const result = await axiosClient.post("/checkuser", userInfo);
      localStorage.setItem("token", result.data.token);

      setToken(result.data.token);

      dispatch(
        logInPartner({ user: result.data.user, token: result.data.token })
      );

      setUserData(result.data);
      toast.success("User logged in successfully!");
    } catch (error) {
      setError(error);
      toast.error("Failed to log in. Please try again!");
    } finally {
      setLoading(false);
    }
  }, []);

  const updateUserData = useCallback(async (updatedUserData) => {
    console.log("Formdate recieved!",updatedUserData);
    setLoading(true);
    try {
      const result = await axiosClient.put(
        `/private/${userState.user.user_id}/updateProfile`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setUserData(result.data);
      toast.success("User data updated successfully!");
    } catch (error) {
      setError(error);
      toast.error("Failed to update user data.");
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteUserData = useCallback(async () => {
    setLoading(true);
    try {
      await axiosClient.delete(`/${userState.user.user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setUserData(null);
      toast.success("User data deleted successfully!");
    } catch (error) {
      setError(error);
      toast.error("Failed to delete user data.");
    } finally {
      setLoading(false);
    }
  }, []);

  const updateUserRole = useCallback(async (newRoleData) => {
    setLoading(true);
    try {
      const result = await axiosClient.put(
        `/${userState.user.user_id}/role`,
        newRoleData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("User role updated successfully!");
      navigate("/complete-profile");
    } catch (error) {
      setError(error);
      toast.error("Failed to update user role.");
    } finally {
      setLoading(false);
    }
  }, []);

  const getAllLocation = useCallback(async () => {
    setLoading(true);
    try {
      const locationData = await axiosClient.get(
        `/private/${userState.user.user_id}/getLocation`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setStates(locationData.data.states);
      setCities(locationData.data.cities);
    } catch (error) {
      setError(error);
      toast.error("Failed to fetch locations.");
    } finally {
      setLoading(false);
    }
  }, []);

  const setUserLocation = useCallback(async (userLocationData) => {
    setLoading(true);
    try {
      await axiosClient.put(
        `/private/${userState.user.user_id}/updateLocation`,
        userLocationData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("User location updated successfully!");
    } catch (error) {
      setError(error);
      toast.error("Failed to update user location.");
    } finally {
      setLoading(false);
    }
  }, []);

  const setUserWorkingDistance = useCallback(async (distance) => {
    try {
      await axiosClient.put(
        `/private/${userState.user.user_id}/setWorkingDistance`,
        distance,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("User working distance updated successfully!");
    } catch (error) {
      setError(error);
      toast.error("Failed to update user working distance.");
    }
  }, []);

  const getFullProfile = useCallback(async (userId) => {
    // console.log(distance);
    try {
      let result = await axiosClient.get(
        `/getFullPublicProfile/${userId}`
      );
      setProfilePgData(result.data);
      // toast.success("Profile fetched successfully!");
    } catch (error) {
      setError(error);
      toast.error("Failed to fetch Profile.");
    }
  }, []);

  return {
    userData,
    loading,
    error,
    cities,
    states,
    profilePgData,
    fetchUserData,
    checkUser,
    updateUserData,
    deleteUserData,
    updateUserRole,
    getAllLocation,
    setUserLocation,
    setUserWorkingDistance,
    getFullProfile,
  };
};

export default usePartner;
