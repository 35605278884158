import React, { useState } from "react";
import style from "../dashboardFormStyle/dashboardFormStyle.module.scss";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import sendTelegramMessage from "../../../../services/Telegram/TelegramService";
const token = process.env.REACT_APP_TELEGRAM_TOKEN;
const chatId = process.env.REACT_APP_TELEGRAM_REQUIREMENTS_CHATID;

export default function PostRequirement() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Custom message
    const message = `
      ******* Partner side requirement ******** \n\nName: ${formData.username} \nEmail: ${formData.email} \nPhone: ${formData.phone} \nMessage: ${formData.message}
    `;

    try {
      await sendTelegramMessage(token, chatId, message);
      toast.success("Thank you! We have recieved your requirements!")
      // Clear form after successful submission
      setFormData({
        username: "",
        email: "",
        phone: "",
        message: "",
      });
      navigate('/dashboard');
    } catch (error) {
      toast.error('Failed to send message. Please try again later.')
    }
  };

  return (
    <div className={style.dashboardFormContainerWrapper}>
      <div className={style.dashboardFormContainer}>
        <form className={style.FormContainer} onSubmit={handleSubmit}>
          <h2 className={style.dashboardFormContinerHeading}>
            Post a Requirement
          </h2>

          <div className={style.inputContainer}>
            <label htmlFor="username">Name</label>
            <input
              type="text"
              id="username"
              value={formData.username}
              onChange={handleChange}
              required
              placeholder="Enter your name"
            />
          </div>

          <div className={style.inputContainer}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="Enter your email"
            />
          </div>

          <div className={style.inputContainer}>
            <label htmlFor="phone">Phone</label>

            <div className={style.phNumberInput}>
              <span>+91</span>
              <input
                minLength={10}
                maxLength={10}
                type="text"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                placeholder="Enter your phone number."
              />
            </div>
          </div>

          <div className={style.inputContainer}>
            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              id="message"
              value={formData.message}
              onChange={handleChange}
              className={style.requirementFormMsg}
              placeholder="Enter your requirements in brief."
              required
            ></textarea>
          </div>

          <div className={style.btnContainer}>
            <button className={style.dashboardFormSubmitButton} type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
