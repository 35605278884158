import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { data } from "./data";
import styles from "../dashboardStyles/dashboardStyle.module.scss";

// Importing Swiper
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

// import { Autoplay } from "swiper/modules";
import usePartner from "../../../../CustomHook/PartnerHook/usePartner";
import BrokenPage from "../../../../Component/BrokenPage/BrokenPage";
import AuthFailed from "../../../../Component/BrokenPage/AuthFailed";
import { useSelector } from "react-redux";
import previewImg from "../../../../Assets/images/DashboardImages/previewImg.png";
import TourCard from "../../../../Component/TourCard/TourCard";
import FeedbackForm from "../../../../Component/FeedbackForm/FeedbackForm";
import toast from "react-hot-toast";
import droneImg from '../../../../Assets/images/DashboardImages/droneImg.png'
const MyDashboard = () => {
  const { fetchUserData, error, loading } = usePartner();
  const navigate = useNavigate();

  const formatString = (input) => {
    if (userState.user) {
      return input
        .toLowerCase()
        .replace(/[^\w\s]/gi, "")
        .replace(/\s+/g, "-");
    }
  };

  const userState = useSelector((state) => state.partner);
  const [showTour, setShowTour] = useState(false);
  useEffect(() => {
    if (userState && userState.user) {
      fetchUserData();
    }
  }, []);

  const [openFeedbackForm, setOpenFeedBackForm] = useState(false);

  const handleCopyLink = () => {
    const profileLink = `${window.location.origin}/profile/${userState.user.userurl}`;
    navigator.clipboard
      .writeText(profileLink)
      .then(() => {
        toast.success("Link copied to clipboard!");
      })
      .catch((error) => {
        toast.error("Failed to copy link!");
      });
  };

  return (
    <div className={styles.myDashboardPage}>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        error.response && error.response.status === 401 ? (
          <AuthFailed />
        ) : (
          <BrokenPage />
        )
      ) : (
        <>
          <div className={styles.pageHeaderBanner}>
            <div className={styles.bannerContent}>
              <div className={styles.bannerText}>
                <h3>
                  Instant Website: Build your professional presence for free.
                </h3>
                <p>
                  No coding, just customization. Showcase your expertise and
                  captivate clients.
                </p>
              </div>
              <div className={styles.bannerBtns}>
                <button>
                  <a
                    className={styles.profileViewBtn}
                    href={`/profile/${userState.user && userState.user.userurl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Preview
                  </a>
                </button>
                <button onClick={handleCopyLink}>Share</button>

                <button onClick={() => {}}>
                  <a
                    href="https://partner.aerialborne.com/profile/saurabh-srivastava-49"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Demo
                  </a>
                </button>
              </div>
            </div>
            <div className={styles.bannerImg}>
              <img src={previewImg} alt="" />
            </div>
          </div>

          <div className={styles.dashCardSection}>
            <div className={styles.dashCardHeader}>
              <h3>Grow & engage your audience</h3>
              <button
                onClick={() => {
                  setShowTour(true);
                }}
              >
                <b>Take a tour</b>
              </button>
            </div>

            <div className={styles.dashCardWrapper}>
              <div
                className={styles.postRequirementCard}
                onClick={() => {
                  navigate('post-requirement');
                }}
              >
                <div className={styles.dashImgContainer}>
                    <img src={droneImg} alt="" />
                  </div>
                <div className={styles.dashCardContent}>
                  <h4 className={styles.dashCardHeading}>
                    Post Requirement
                  </h4>
                  <p>Get drone services anywhere with AerialBorne.</p>
                </div>
              </div>

              {data.map(({ id, title, info, image, path, description }) => (
                <div
                  key={id}
                  className={styles.dashboardPageCard}
                  onClick={() => {
                    navigate(path);
                  }}
                >
                  <div className={styles.dashImgContainer}>
                    <img src={image} alt="" />
                  </div>
                  <div className={styles.dashCardContent}>
                    <h4 className={styles.dashCardHeading}>{title}</h4>
                    <p>{description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.contactUs}>
            <div className={styles.contactUsContent}>
              <h2>Talk to our team</h2>
              <h3>You can always reach out to us if you need any help.</h3>
              <div className={styles.num}>
                <p>
                  <b>
                    <span></span>
                    Call us at <a href="tel:+91-7357017420">+91-7357017420</a>
                  </b>
                </p>
                <p>
                  <b>
                    <span></span>
                    Mail us at{" "}
                    <a href="mailto:support@aerialborne.com">
                      support@aerialborne.com
                    </a>
                  </b>
                </p>
              </div>

              <button
                className={styles.contactUsBtn}
                onClick={() => {
                  setOpenFeedBackForm(true);
                }}
              >
                Give us feedback
              </button>
            </div>
            <div style={styles.contactUsImg}>
              <img src="" alt="" />
            </div>
          </div>
          {openFeedbackForm && (
            <FeedbackForm setOpenFeedBackForm={setOpenFeedBackForm} />
          )}
          {showTour && (
            <TourCard showTour={showTour} setShowTour={setShowTour} />
          )}
        </>
      )}
    </div>
  );
};

export default MyDashboard;
