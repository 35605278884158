import React, { useState } from "react";
import styles from "./sidebar.module.css"; // Import CSS module

import youtube from "./images/youtube.png";
import instagram from "./images/instagram.png";
import facebook from "./images/facebook.png";
import linkedin from "./images/linkedin.png";
import pushpin from "./images/push-pin.png";
import skills from "./images/skills.png";
import { Button } from "../Button/Button";
import Avatar from "../Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowRightLong,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faLinkedin,
  faSquareInstagram,
  faSquareYoutube
} from "@fortawesome/free-brands-svg-icons";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { logOutPartner } from "../../Redux/Partner/PartnerAuthSlice";

const Sidebar = ({ userData }) => {
  const showUserProfile = () => {};
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [navLinks, setNavLinks] = useState(false);
  const [socialLinks, setSocialLinks] = useState(false);
  const [showSkills, setShowSkills] = useState(false);

  const handleLogout = () => {
    dispatch(logOutPartner({}));
    toast.success("User logged out successfully!");
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className={styles.myleft}>
      <div className={styles.myinner}>
        <div className={styles.profileCont}>
          <div
            className={styles.profileContainer}
            onClick={() => {
              navigate("/dashboard/partner/editprofile");
            }}
          >
            {userData && userData.profile_image_url ? (
              <div className={styles.profileIcon}>
                <img src={userData.profile_image_url} alt="" />
              </div>
            ) : (
              <Avatar name={userData ? userData.username : "Fetching..."} />
            )}
            <h3 className={styles.pilotNameCont}>
              <p>{userData ? userData.username : "Fetching..."}</p>
              <span
                title={userData && userData.partner_role}
                className={styles.pilotRole}
                onClick={() => {
                  if (userData && !userData.partner_role) {
                    navigate("/role");
                  }
                }}
              >
                {userData && userData.partner_role
                  ? userData.partner_role.charAt(0).toUpperCase() +
                    userData.partner_role.slice(1)
                  : "Add Role"}
              </span>
            </h3>
          </div>
        </div>

        <div className={styles.scrollNav}>
          <div className={styles.navOptionsContainer}>
            <div className={styles.navOptions}>
              <div
                className={styles.navOptionHeader}
                onClick={() => {
                  setSocialLinks(false);
                  setShowSkills(false);
                  setNavLinks(!navLinks);
                }}
              >
                <h5>Controls</h5>
                {navLinks ? (
                  <FontAwesomeIcon icon={faAngleUp} />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} />
                )}
              </div>
              {navLinks && (
                <div className={styles.subOptions}>
                  <p
                    className={styles.navOptionItems}
                    onClick={() => {
                      navigate("/dashboard/equipment");
                    }}
                  >
                    <span>Equipments</span>
                    <FontAwesomeIcon
                      className={styles.rightArrowIcon}
                      icon={faArrowRightLong}
                    />
                  </p>
                  <p
                    className={styles.navOptionItems}
                    onClick={() => {
                      navigate("/dashboard/certificate");
                    }}
                  >
                    <span>Certificates</span>
                    <FontAwesomeIcon
                      className={styles.rightArrowIcon}
                      icon={faArrowRightLong}
                    />
                  </p>
                  <p
                    className={styles.navOptionItems}
                    onClick={() => {
                      navigate("/dashboard/myexperience");
                    }}
                  >
                    <span>Experience</span>
                    <FontAwesomeIcon
                      className={styles.rightArrowIcon}
                      icon={faArrowRightLong}
                    />
                  </p>
                  <p
                    className={styles.navOptionItems}
                    onClick={() => {
                      navigate("/dashboard/showreel");
                    }}
                  >
                    <span>Showreel</span>
                    <FontAwesomeIcon
                      className={styles.rightArrowIcon}
                      icon={faArrowRightLong}
                    />
                  </p>
                  <p
                    className={styles.navOptionItems}
                    onClick={() => {
                      navigate("/dashboard/service");
                    }}
                  >
                    <span>Services</span>
                    <FontAwesomeIcon
                      className={styles.rightArrowIcon}
                      icon={faArrowRightLong}
                    />
                  </p>
                </div>
              )}
            </div>

            <div className={styles.navOptions}>
              <div
                className={styles.navOptionHeader}
                onClick={() => {
                  setNavLinks(false);
                  setShowSkills(false);
                  setSocialLinks(!socialLinks);
                }}
              >
                <h5>Social Profile</h5>
                {socialLinks ? (
                  <FontAwesomeIcon icon={faAngleUp} />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} />
                )}
                {/* faAngleUp */}
              </div>
              {socialLinks && (
                <div className={styles.subOptions}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    title="LinkedIn"
                    className={styles.navOptionItems}
                    onClick={()=>{
                      navigate("/dashboard/partner/editprofile", { state: { social:"social" } })
                    }}
                  >
                    <span>
                      <FontAwesomeIcon
                        className={styles.socialIcons}
                        icon={faLinkedin}
                      />
                      Linkedin
                    </span>
                    <FontAwesomeIcon
                      className={styles.rightArrowIcon}
                      icon={faArrowRightLong}
                    />
                  </a>

                  <a
                    target="_blank"
                    rel="noreferrer"
                    title="Facebook"
                    className={styles.navOptionItems}
                    onClick={()=>{
                      navigate("/dashboard/partner/editprofile", { state: { social:"social" } })
                    }}
                  >
                    <span>
                      <FontAwesomeIcon
                        className={styles.socialIcons}
                        icon={faFacebook}
                      />
                      Facebook
                    </span>
                    <FontAwesomeIcon
                      className={styles.rightArrowIcon}
                      icon={faArrowRightLong}
                    />
                  </a>

                  <a
                    target="_blank"
                    rel="noreferrer"
                    title="Instagram"
                    className={styles.navOptionItems}
                    onClick={()=>{
                      navigate("/dashboard/partner/editprofile", { state: { social:"social" } })
                    }}
                  >
                    <span>
                      <FontAwesomeIcon
                        className={styles.socialIcons}
                        icon={faSquareInstagram}
                      />{" "}
                      Instagram
                    </span>
                    <FontAwesomeIcon
                      className={styles.rightArrowIcon}
                      icon={faArrowRightLong}
                    />
                  </a>

                  <a
                    target="_blank"
                    rel="noreferrer"
                    title="Instagram"
                    className={styles.navOptionItems}
                    onClick={()=>{
                      navigate("/dashboard/partner/editprofile", { state: { social:"social" } })
                    }}
                  >
                    <span>
                      <FontAwesomeIcon
                        className={styles.socialIcons}
                        icon={faSquareYoutube}
                      />{" "}
                      Youtube
                    </span>
                    <FontAwesomeIcon
                      className={styles.rightArrowIcon}
                      icon={faArrowRightLong}
                    />
                  </a>
                </div>
              )}
            </div>

            <div className={styles.navOptions}>
              <div
                className={styles.navOptionHeader}
                onClick={() => {
                  setNavLinks(false);
                  setSocialLinks(false);
                  setShowSkills(!showSkills);
                }}
              >
                <h5>Skills</h5>
                {showSkills ? (
                  <FontAwesomeIcon icon={faAngleUp} />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} />
                )}
                {/* faAngleUp */}
              </div>

              {showSkills && (
                <div>
                  <div className={styles.skillsFlex}>
                    {userData && userData.skills ? (
                      userData.skills.split(",").map((skill, index) => (
                        <div key={index} className={styles.skillsItems}>
                          <p>{skill}</p>
                        </div>
                      ))
                    ) : (
                      <p>No Skills Added yet</p>
                    )}
                  </div>
                  <div className={styles.seeAllBtnCont}>
                    <button
                      className={styles.seeAllBtn}
                      onClick={() => {
                        navigate("/dashboard/skills");
                      }}
                    >
                      Add More Skills{" "}
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className={styles.navOptions}>
              <div className={styles.navOptionHeader}>
                <h5>Missions</h5>
                <p className={styles.commingSoon}>Coming Soon</p>
              </div>
            </div>

            <div className={styles.navOptions}>
              <div className={styles.navOptionHeader}>
                <h5>Image Gallery</h5>
                <p className={styles.commingSoon}>Coming Soon</p>
              </div>
            </div>
          </div>
          {/* <div className={styles.socialMediaCont}>
            <div className={styles.socialHeading}>
              <div className={styles.headingIconCont}>
                <img src={pushpin} alt="pushpin" />
              </div>
              <h4>Social Media Links</h4>
            </div>

            <div className={styles.socialCont}>
              <div className={styles.iconCont}>
                <img src={instagram} alt="Instagram" title="Instagram" />
              </div>
              <div>
                <a
                  href={userData?.instagram_link}
                  target="_blank"
                  rel="noreferrer"
                  title="Instagram"
                >
                  <p>Instagram</p>
                </a>
              </div>
            </div>
            <div className={styles.socialCont}>
              <div className={styles.iconCont}>
                <img src={facebook} alt="Facebook" title="Facebook" />
              </div>
              <div>
                <a
                  href={userData?.facebook_link}
                  target="_blank"
                  rel="noreferrer"
                  title="Facebook"
                >
                  <p>Facebook</p>
                </a>
              </div>
            </div>

            <div className={styles.socialCont}>
              <div className={styles.iconCont}>
                <img src={linkedin} alt="LinkedIn" title="LinkedIn" />
              </div>
              <div>
                <a
                  href={userData?.linkedin_link}
                  target="_blank"
                  rel="noreferrer"
                  title="LinkedIn"
                >
                  <p>LinkedIn</p>
                </a>
              </div>
            </div>
          </div>

          <div className={styles.skillsMediaCont}>
            <div className={styles.skillsHeading}>
              <div className={styles.headingIconCont}>
                <img src={skills} alt="skills" />
              </div>
              <h4>Skills</h4>
            </div>

            <div className={styles.skillsFlex}>
              {userData && userData.skills ? (
                userData.skills.split(",").map((skill, index) => (
                  <div key={index} className={styles.skillsButton}>
                    <p>{skill}</p>
                  </div>
                ))
              ) : (
                <p>No Skills Added yet</p>
              )}
            </div>

            <div className={styles.seeAllBtnCont}>
              <button
                className={styles.seeAllBtn}
                onClick={() => {
                  navigate("/dashboard/skills");
                }}
              >
                Add More Skills{" "}
              </button>
            </div>
          </div> */}
        </div>
        <button className={styles.logoutBtn} onClick={handleLogout}>
          <FontAwesomeIcon
            className={styles.logoutBtnIcon}
            icon={faRightFromBracket}
          />
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
