
import { combineReducers } from '@reduxjs/toolkit';
import socialReducer from "./Social/ScoialSlice"
import partnerReducer from "./Partner/PartnerAuthSlice"

import flyingspotReducer from "./FlyingSpot/FlyingSpotSlice"

const rootReducer = combineReducers({
  social: socialReducer,
  partner:partnerReducer,
  flyingspot: flyingspotReducer,
});

export default rootReducer;
