import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  user: null,
  token: null,
};

const PartnerAuthSlice = createSlice({
  name: "PartnerAuth",
  initialState,

  reducers: {
    logInPartner(state, action) {
      const { user, token } = action.payload;

      return {
        ...state,
        user: user,
        isLoggedIn: true,
        token: token,
      };
    },

    logOutPartner(state, action) {
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        token: null,
      };
    },
  },
});

export const { logInPartner, logOutPartner } = PartnerAuthSlice.actions;

export default PartnerAuthSlice.reducer;
