import React, { useEffect, useState } from "react";
import styles from "../dashboardFormStyle/dashboardFormStyle.module.scss";
import Avatar from "../../../../Component/Avatar/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faCloudArrowUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import usePartner from "../../../../CustomHook/PartnerHook/usePartner";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useCustomHookS3 from "../../../../CustomHook/AvatarPreview/useCustomHookS3";
import AuthFailed from "../../../../Component/BrokenPage/AuthFailed";
import BrokenPage from "../../../../Component/BrokenPage/BrokenPage";
  import { useLocation } from "react-router-dom";

const PartnerEditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const { updateUserData, fetchUserData } = usePartner();
  const [imgUploadModal, setImgUploadModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { uploadFileInS3, imageUrl, uploadProgress, error } = useCustomHookS3();
  const userState = useSelector((state) => state.partner);
  const location = useLocation();

  const social = location.state ? location.state.social : "profile"

  useEffect(()=>{
    setActiveTab(social);
  },[social])

  const [formData, setFormData] = useState({
    username: "",
    contact_number: "",
    address: "",
    facebook: "",
    instagram: "",
    linkedin: "",
    youtube: "",
    profile_image_url: "",
  });

  const [img, setImg] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      setFormData((prevData) => ({
        ...prevData,
        profile_image_url: imageUrl,
      }));
      setSelectedImage(imageUrl);
      setUploadSuccess(true);
      setUploading(false);
      setTimeout(() => {
        setImgUploadModal(false);
      }, 500);
    }
  }, [imageUrl]);

  useEffect(() => {
    // Populate form data with user information when userState changes
    if (userState.user) {
      console.log(userState.user);
      setFormData({
        username: userState.user.username || "",
        contact_number: userState.user.contact_number || "",
        profile_image_url: userState.user.profile_image_url || "",
        address: userState.user.address || "",
        facebook: userState.user.facebook_link || "",
        instagram: userState.user.instagram_link || "",
        linkedin: userState.user.linkedin_link || "",
        youtube: userState.user.youtube_link || "",
      });
    }
  }, [userState]);

  const handleChange = (e) => {
    if (e.target.id === "fileInput") {
    } else {
      setFormData({ ...formData, [e.target.id]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Dispatch action to update user profile with the new form data
    updateUserData(formData);
    // fetchUserData();
    navigate("/dashboard");
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleFileInputChange = (event) => {
    const { files } = event.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImg(file);
        setSelectedImage(reader.result);
        setUploadSuccess(false);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageUpload = () => {
    if (img) {
      const key = `${Date.now()}${img.name}`;
      const contentType = img.type;
      setUploading(true);
      uploadFileInS3(img, key, contentType);
    }
  };

  return (
    <div className={styles.outerProfile}>
      {error ? (
        error.response && error.response.status === 401 ? (
          <AuthFailed />
        ) : (
          <BrokenPage />
        )
      ) : (
        <div className={styles.innerProfile}>
          <div className={styles.editProfile}>
            <h3>
              {activeTab === "profile"
                ? "Edit Profile Information"
                : "Edit Social Information"}
            </h3>
            <div className={styles.profileCont}>
              <div className={styles.profileIcon}>
                {userState.user && userState.user.profile_image_url ? (
                  <img src={formData.profile_image_url} alt="" />
                ) : (
                  <Avatar
                    name={
                      userState.user ? userState.user.username : "Fetching..."
                    }
                    width="100px"
                    height="100px"
                    fontSize="14px"
                  />
                )}
              </div>
              <div
                className={styles.editIcon}
                onClick={() => {
                  setImgUploadModal(true);
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </div>
            </div>
            {imgUploadModal && (
              <div className={styles.uploadModal}>
                <FontAwesomeIcon
                  className={styles.closeModal}
                  title="Close"
                  icon={faXmark}
                  onClick={() => {
                    setImgUploadModal(false);
                  }}
                />
                <div className={styles.DragUploaderHeading}>
                  Upload Your Profile
                </div>

                <div className={styles.DragUploaderImageContainer}>
                  {/* Conditionally render selected image or upload icon */}
                  <input
                    type="file"
                    id="fileInput"
                    className={styles.fileInput}
                    onChange={handleFileInputChange}
                  />
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="Selected"
                      className={styles.previewImage}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCloudArrowUp}
                      className={styles.uploadIcon}
                    />
                  )}
                </div>
                {selectedImage && (
                  <div className={styles.draguploadbutton}>
                    {uploadSuccess ? (
                      <div>Image Uploaded Successfully</div>
                    ) : (
                      <button
                        className={styles.dashboardFormSaveButton}
                        onClick={handleImageUpload}
                      >
                        {uploading ? "Uploading Image..." : "Upload Image"}
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className={styles.tabContainer}>
              <div
                className={`${styles.tab} ${
                  activeTab === "profile" && styles.activeTab
                }`}
                onClick={() => handleTabChange("profile")}
              >
                Profile Information
              </div>
              <div
                className={`${styles.tab} ${
                  activeTab === "social" && styles.activeTab
                }`}
                onClick={() => handleTabChange("social")}
              >
                Social Information
              </div>
            </div>
            <form className={styles.inputCont} onSubmit={handleSubmit}>
              {/* Profile Information */}
              {activeTab === "profile" && (
                <>
                  <div className={styles.username}>
                    <label className={styles.inputLabel} htmlFor="username">
                      Username:
                    </label>
                    <input
                      required
                      placeholder="Enter Your Username"
                      type="text"
                      id="username"
                      value={formData.username}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={styles.mobileNumber}>
                    <label
                      className={styles.inputLabel}
                      htmlFor="mobile_number"
                    >
                      Contact Number:
                    </label>
                    <div className={styles.phNumberInput}>
                      <span>+91</span>
                      <input
                        required
                        placeholder="Enter Your Contact Number"
                        type="text"
                        id="contact_number"
                        minLength={10}
                        maxLength={10}
                        value={formData.contact_number}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className={styles.email}>
                    <label className={styles.inputLabel} htmlFor="email">
                      Email:
                    </label>
                    <input
                      readOnly
                      type="email"
                      id="email"
                      value={ userState.user ? userState.user.email : "Please Login Again! Can't fetch your data!"}
                    />
                  </div>
                  <div className={styles.address}>
                    <label className={styles.inputLabel} htmlFor="address">
                      City:
                    </label>
                    <input
                      required
                      placeholder="Enter Your City"
                      type="text"
                      id="address"
                      value={formData.address}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}

              {/* Social Information */}
              {activeTab === "social" && (
                <>
                  <div className={styles.username}>
                    <label htmlFor="facebook">Facebook:</label>
                    <input
                      type="url"
                      id="facebook"
                      value={formData.facebook}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={styles.mobileNumber}>
                    <label htmlFor="instagram">Instagram:</label>
                    <input
                      type="url"
                      id="instagram"
                      value={formData.instagram}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={styles.email}>
                    <label htmlFor="linkedin">Linkedin:</label>
                    <input
                      type="url"
                      id="linkedin"
                      value={formData.linkedin}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={styles.email}>
                    <label htmlFor="youtube">Youtube:</label>
                    <input
                      type="url"
                      id="youtube"
                      value={formData.youtube}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}

              <div className={styles.profileButtonCont}>
                <button className={styles.saveProfileBtn} type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerEditProfile;
