import { useState, useCallback } from "react";
import axiosClient from "../../Utils/Axios/AxiosClient";
import toast from 'react-hot-toast'; // Import react-hot-toast
import { useSelector } from "react-redux";

const useExperience = () => {
  const [experiences, setExperiences] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const userState = useSelector((state) => state.partner);

  const getAllExperiences = useCallback(async () => {
    setLoading(true);
    try {
      const result = await axiosClient.get(
        `/${userState.user.user_id}/experience`,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setExperiences(result.data.droneExperiences);
      toast.success('Experiences fetched successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to fetch experiences. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  const postExperience = useCallback(async (experienceData) => {
    setLoading(true);
    try {
      const result = await axiosClient.post(`/${userState.user.user_id}/experience/`, experienceData,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setExperiences((prevExperiences) => [...prevExperiences, result.data]);
      toast.success('Experience added successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to add experience. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  const updateExperience = useCallback(async (experienceId, updatedData) => {
    setLoading(true);
    try {
      const result = await axiosClient.put(
        `/${userState.user.user_id}/experience/${experienceId}`,
        updatedData,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      const data = await axiosClient.get(
        `/${userState.user.user_id}/experience`,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setExperiences(data.data.droneExperiences);
      toast.success('Experience updated successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to update experience. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteExperience = useCallback(async (experienceId) => {
    setLoading(true);
    try {
      await axiosClient.delete(
        `/${userState.user.user_id}/experience/${experienceId}`,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      // setExperiences((prevExperiences) =>
      //   prevExperiences.filter(
        //     (experience) => experience.exp_id !== experienceId
        //   )
        // );
        const result = await axiosClient.get(
          `/${userState.user.user_id}/experience`,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );
      setExperiences(result.data.droneExperiences);
      toast.success('Experience deleted successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to delete experience. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    experiences,
    loading,
    error,
    getAllExperiences,
    postExperience,
    updateExperience,
    deleteExperience,
  };
};

export default useExperience;
