import React from 'react'
import style from "./SingleFlyingSpotDetail.module.css"
import map from "../../../Assets/images/drone.png"

const SingleFlyingSpotDetail = () => {
  return (
    <>
    <div className={style.box}>

      <div className={style.container}>
        <div className={style.leftContainer}>
          <header><h1>Single Spot Name</h1>
  <p>Spot location | Total Views </p></header>
  
  <section><h3>Followers:1222</h3>
  <h4>Snaps:1122 </h4>
  <button>follow</button>
  <div className={style.ratingContainer}>Rate this spot: <span><img src={""}></img></span></div></section>

        </div>
        <div className={style.rightContainer}>
          <img src={map}>
        </img></div>
        </div>
        <div className={style.flyingspotSnapContainer}>
        <header>
<h1>Snaps</h1>
<button>Add a snap</button>
        </header>

        </div>
    </div>
    </>
  )
}

export default SingleFlyingSpotDetail