import { useState, useCallback } from 'react';
import axiosClient from '../../Utils/Axios/AxiosClient';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const useShowreel = () => {
  const [showreels, setShowreels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const userState = useSelector((state) => state.partner);

  // Fetch all showreels
  const getAllShowreels = useCallback(async () => {
    setLoading(true);
    try {
      const result = await axiosClient.get(`/${userState.user.user_id}/showreels`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setShowreels(result.data.showreels);
      toast.success('Showreels fetched successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to fetch showreels. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  // Post a new showreel
  const postShowreel = useCallback(async (showreelData) => {
    setLoading(true);
    try {
      const result = await axiosClient.post(`/${userState.user.user_id}/showreels`, showreelData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setShowreels((prevShowreels) => [...prevShowreels, result.data]);
      toast.success('Showreel added successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to add showreel. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  // Update a showreel
  const updateShowreel = useCallback(async (showreelId, updatedData) => {
    setLoading(true);
    try {
      const result = await axiosClient.put(`/${userState.user.user_id}/showreels/${showreelId}`, updatedData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setShowreels((prevShowreels) =>
        prevShowreels.map((showreel) =>
          showreel.id === showreelId ? result.data : showreel
        )
      );
      toast.success('Showreel updated successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to update showreel. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  // Delete a showreel
  const deleteShowreel = useCallback(async (showreelId) => {
    setLoading(true);
    try {
      await axiosClient.delete(`/${userState.user.user_id}/showreels/${showreelId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setShowreels((prevShowreels) =>
        prevShowreels.filter((showreel) => showreel.showreel_id !== showreelId)
      );
      toast.success('Showreel deleted successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to delete showreel. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    showreels,
    loading,
    error,
    getAllShowreels,
    postShowreel,
    updateShowreel,
    deleteShowreel,
  };
};

export default useShowreel;
