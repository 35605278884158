import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../authCommonStyle/authCommonStyle.module.css";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import usePartner from "../../../../CustomHook/PartnerHook/usePartner";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

const LoginMethod = () => {
  const navigate = useNavigate();

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const { checkUser } = usePartner();

  const userState = useSelector((state) => state.partner);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (userState.user.partner_role) {
        navigate("/dashboard");
        return;
      }
      navigate("/role");
    }
  }, [userState]);

  const handleGoogleLoginSuccess = (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    const userInfo = {
      fullName: decoded.name,
      googleId: decoded.sub,
      profileImage: decoded.picture,
      email: decoded.email,
    };
    checkUser(userInfo);
  };

  const handleGoogleLoginFailure = () => {
    toast.error("Authentication failed! Please try again!");
  };

  return (
    <div className={styles.AuthContainer}>
      <h1>Welcome</h1>
      <h2>Please login with your google account.</h2>

      <div className={styles.letgetstarted}>
        <p>Let's get Started</p>
        
        <div className={styles.googleLoginContainer}>
          <GoogleOAuthProvider className={styles.GoogleLogin} clientId={clientId}>
            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={handleGoogleLoginFailure}
              width="280"
            />
          </GoogleOAuthProvider>
        </div>
      </div>
      <div className={styles.authLoginPara}>
        <p>By continuing you agree to our</p>
        <p>
          <a href="https://aerialborne.com/refunds" target="_blank"><span>Terms & Condition</span></a> and 
          <a href="https://aerialborne.com/privacy" target="_blank"><span> Privacy Policy</span></a>
        </p>
      </div>
    </div>
  );
};

export default LoginMethod;
