import React from 'react'
import style from "./AddFlyingSpotLocationDetail.module.css"
import map from "../../../Assets/images/drone.png"

const AddFlyingSpotLocationDetail = () => {
  return (
    <div className={style.Box} >
      <h1>Add New Flying Spot  </h1>
      <div className={style.FlyingSpotDetailForm}>
          <h1 >Choose Spot Location</h1>
     <div className={style.FlyingSpotDetailFormContainer}>
     <h1>choose location Address</h1>
     <div className={style.inputbox}>
     <label>Address</label>
      <input placeholder='Add Address'></input>
     </div>
     <div className={style.stack}>
     <div className={style.inputbox}>
    <label>City</label>
      <input placeholder='select city'></input>
     </div>
     <div className={style.inputbox}>
     <label>State/Province</label>
      <input placeholder='select province'></input>
     </div>
     </div>
     <div className={style.stack}>
     <div className={style.inputbox}>
     <label>Zip/Postal code</label>
      <input></input>
     </div>
     <div className={style.inputbox}>
     <label>Country/Region</label>
      <input placeholder='Select country/region'></input>
     </div>
     </div>
  
  <div className={style.imageContainer}><img src={map}></img></div>
  <div className={style.saveBtn} ><button >Save</button></div>
  
          </div>
  
      </div>
  
    </div>
    )
}

export default AddFlyingSpotLocationDetail