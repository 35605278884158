import { useState, useCallback } from "react";
import axiosClient from "../../Utils/Axios/AxiosClient";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const useService = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const userState = useSelector((state) => state.partner);

  // Fetch all services
  const getAllServices = useCallback(async () => {
    setLoading(true);
    try {
      const result = await axiosClient.get(
        `/${userState.user.user_id}/services`,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setServices(result.data.services);
      toast.success("Services fetched successfully!");
    } catch (error) {
      setError(error);
      toast.error("Failed to fetch services. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  // Post a new service
  const postService = useCallback(async (serviceData) => {
    setLoading(true);
    try {
      const result = await axiosClient.post(`/${userState.user.user_id}/services`,serviceData,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      toast.success("Service added successfully!");
      setServices((prevServices) => [...prevServices, result.data]);
    } catch (error) {
      setError(error);
      toast.error("Failed to add service. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  // Update a service
  const updateService = useCallback(async (serviceId, updatedData) => {
    setLoading(true);
    try {
      const result = await axiosClient.put(
        `/${userState.user.user_id}/services/${serviceId}`, updatedData,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setServices((prevServices) =>
        prevServices.map((service) =>
          service.id === serviceId ? result.data : service
        )
      );
      toast.success("Service updated successfully!");
    } catch (error) {
      setError(error);
      toast.error("Failed to update service. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  // Delete a service
  const deleteService = useCallback(async (serviceId) => {
    setLoading(true);
    try {
      await axiosClient.delete(
        `/${userState.user.user_id}/services/${serviceId}`,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setServices((prevServices) =>
        prevServices.filter((service) => service.service_id !== serviceId)
      );
      toast.success("Service deleted successfully!");
    } catch (error) {
      setError(error);
      toast.error("Failed to delete service. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    services,
    loading,
    error,
    getAllServices,
    postService,
    updateService,
    deleteService,
  };
};

export default useService;
