import droneImg from '../../../../Assets/images/DashboardImages/droneImg.png';
import equipmentImg from '../../../../Assets/images/DashboardImages/equipmentImage.png';
import servicesImg from '../../../../Assets/images/DashboardImages/servicesImg.png';
import showreelImg from '../../../../Assets/images/DashboardImages/showreelImg.png';
import certificatImg from '../../../../Assets/images/DashboardImages/certificatesImg.png';
import experienceImg from '../../../../Assets/images/DashboardImages/experienceImg.png';

export const data = [
  // {
  //   id: 1,
  //   // icon: <FaCrown />,
  //   title: "Drone Management",
  //   image: droneImg,
  //   path: "drone",
  // },

  {
    id: 2,
    // icon: <FaCrown />,
    title: "Equipments",
    image: equipmentImg,
    path: "equipment",
    description: "Manage your drones & equipment inventory easily."
  },
  {
    id: 3,
    // icon: <FaCrown />,
    title: "Certificates",
    image: certificatImg,
    path: "certificate",
    description: "Upload your certifications to build trust and credibility."
  },
  {
    id: 4,
    // icon: <FaCrown />,
    title: "Experience",
    image: experienceImg,
    path: "myexperience",
    description: "Demonstrate your professional journey visually"
  },
  {
    id: 5,
    // icon: <FaCrown />,
    title: "Showreel",
    image: showreelImg,
    path: "showreel",
    description: "Upload your best work, including photos videos."
  },
  {
    id: 6,
    // icon: <FaCrown />,
    title: "Services",
    image: servicesImg,
    path: "service",
    description: "Highlight your service offerings."
  },
];
