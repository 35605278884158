import { useState, useCallback, useEffect } from 'react';
import axiosClient from '../../Utils/Axios/AxiosClient';
import toast from 'react-hot-toast'; 

import { useSelector } from 'react-redux';

const useDrone = () => {
  const [drones, setDrones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const userState = useSelector((state) => state.partner);

  // Fetch all drones
  const getAllDrones = useCallback(async () => {
    setLoading(true);
    try {
      const result = await axiosClient.get(`/${userState.user.user_id}/drones`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setDrones(result.data.drones);
      toast.success('Drones fetched successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to fetch drones. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  // Post a new drone
  const postDrone = useCallback(async (droneData) => {
    setLoading(true);
    try {
      const result = await axiosClient.post(`/${userState.user.user_id}/drones`, droneData,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setDrones((prevDrones) => [...prevDrones, result.data]);
      toast.success('Drone added successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to add drone. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  // Update a drone
  const updateDrone = useCallback(async (droneId, updatedData) => {
    setLoading(true);
    try {
      const result = await axiosClient.post(`/${userState.user.user_id}/drones`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setDrones((prevDrones) =>
        prevDrones.map((drone) => (drone.id === droneId ? result.data : drone))
      );
      toast.success('Drone updated successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to update drone. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  // Delete a drone
  const deleteDrone = useCallback(async (droneId) => {
    setLoading(true);
    try {
      await axiosClient.delete(`/${userState.user.user_id}/drones/${droneId}`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setDrones((prevDrones) => prevDrones.filter((drone) => drone.drone_id !== droneId));
      toast.success('Drone deleted successfully!');
    } catch (error) {
      setError(error);
      toast.error('Failed to delete drone. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    drones,
    loading,
    error,
    getAllDrones,
    postDrone,
    updateDrone,
    deleteDrone,
  };
};

export default useDrone;
