import React from "react";
import { Provider } from "react-redux";
import "./App.css";
import Router from "./Routes";
import { store } from "./Redux/Store";
import { Toaster } from 'react-hot-toast';

const App = () => {
  return (
    <Provider store={store}>
        <div className="app">
          <Toaster/>
          <Router />
        </div>
    </Provider>
  );
};

export default App;
