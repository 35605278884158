import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import axiosClient from "../../Utils/Axios/AxiosClient";
import toast from "react-hot-toast";

const useCustomHookS3 = () => {
  const [imageUrl, setImageUrl] = useState();
  const userState = useSelector((state) => state.partner);
  const [error, setError] = useState(null);

  const uploadFileInS3 = async (selectedFile, key, contenttype) => {
    try {
      // Get the upload URL
      const response = await axiosClient.post(
        `/${userState.user.user_id}/upload/uploadImage`,
        {
          filename: key,
          contenttype: contenttype,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const { uploadUrl } = response.data;
      // Upload the file to S3
      await axios.put(uploadUrl, selectedFile);

      // Get the URL of the uploaded image
      const imageUrlResponse = await axiosClient.get(
        `/${userState.user.user_id}/upload/getImageURL/${key}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const imageUrl = imageUrlResponse.data.downloadUrl;

      // Create a new URL object
      const parsedUrl = new URL(imageUrl);

      // Extract the base URL
      const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}${parsedUrl.pathname}`;
      // Update the imageUrl state variable
      setImageUrl(baseUrl);
      toast.success("Image Uploaded Successfully!")
    } catch (error) {
      setError(error);
      toast.error("Image upload failed! Please try again!")
    }
  };

  return { error, uploadFileInS3, imageUrl: imageUrl };
};

export default useCustomHookS3;
